import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';

import {
    isAudio,
    isPdf,
    isPowerpoint,
    isVideo,
} from '../components/vault/helpers';

import {
    checkAceDeckIsWideScreen,
    checkVaultDeckIsWideScreen,
    dereferenceRelationships,
    downloadDocumentAudio,
    downloadDocumentRendition,
    downloadDocumentSource,
    downloadDocumentVideo,
    fetchAllVaultDocuments,
    fetchContent,
    fetchCountryCodes,
    fetchLocals,
    fetchNewCategories,
    fetchPdfThumbnail,
    fetchPodcastThumbnail,
    fetchWorker,
    postForm,
    searchVault,
    tokenize,
    updateDeckData,
    updateForm,
    uploadPdfThumbnail,
} from '../redux/actions';

import DeckUploader from '../components/uploader/DeckUploader';
import PdfUploader from '../components/uploader/PdfUploader';
import PodcastUploader from '../components/uploader/PodcastUploader';
import VideoUploader from '../components/uploader/VideoUploader';
import LinkThumbnailUploader from '../components/uploader/LinkThumbnailUploader';

const Uploader = () => {
    const dispatch = useDispatchRequest();
    const [tab, setTab] = useState('deck');
    const form = useSelector(state => state.form);
    const username = useSelector(state => state.login.username);

    const { data: newCategories, loading: loadingCategories } = useQuery({
        type: 'FETCH_VAULT_NEW_CATEGORIES',
        action: fetchNewCategories,
        autoLoad: true,
        multiple: true,
    });
    const { data: countries, loading: loadingCountryCodes } = useQuery({
        type: 'FETCH_VAULT_COUNTRY_CODES',
        action: fetchCountryCodes,
        autoLoad: true,
        multiple: true,
        meta: {
            cache: true,
        },
    });
    const { data: documents, loading: loadingDocuments } = useQuery({
        type: 'FETCH_VAULT_ALL_DOCUMENTS',
        action: fetchAllVaultDocuments,
        autoLoad: true,
        multiple: true,
    });
    const { data: locals, loading: loadingLocals } = useQuery({
        type: 'FETCH_LOCALS',
        action: fetchLocals,
        autoLoad: true,
        multiple: true,
        meta: {
            cache: true,
        },
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (
            !loadingCategories &&
            !loadingCountryCodes &&
            !loadingDocuments &&
            !loadingLocals
        ) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [
        loadingCategories,
        loadingCountryCodes,
        loadingDocuments,
        loadingLocals,
    ]);

    const renderForm = eventKey => {
        switch (eventKey) {
            case 'video':
                return (
                    <VideoUploader
                        newCategories={newCategories}
                        countries={countries}
                        locals={locals}
                        languages={locals?.languages}
                        videos={documents.filter(doc => isVideo(doc.format__v))}
                        form={form}
                        username={username}
                        fetchContent={type => dispatch(fetchContent(type))}
                        downloadVideo={vaultId =>
                            dispatch(downloadDocumentVideo(vaultId))
                        }
                        dereferenceRelationships={id =>
                            dispatch(dereferenceRelationships(id))
                        }
                        postForm={form => dispatch(postForm(form))}
                        searchVault={vaultId => dispatch(searchVault(vaultId))}
                        updateForm={(key, val) =>
                            dispatch(updateForm(key, val))
                        }
                        updateDeckData={json => dispatch(updateDeckData(json))}
                        fileType={eventKey}
                    />
                );

            case 'podcast':
                return (
                    <PodcastUploader
                        newCategories={newCategories}
                        countries={countries}
                        languages={locals?.languages}
                        locals={locals}
                        podcasts={documents.filter(doc =>
                            isAudio(doc.format__v)
                        )}
                        form={form}
                        username={username}
                        downloadAudio={vaultId =>
                            dispatch(downloadDocumentAudio(vaultId))
                        }
                        dereferenceRelationships={id =>
                            dispatch(dereferenceRelationships(id))
                        }
                        fetchPodcastThumbnail={vaultId =>
                            dispatch(fetchPodcastThumbnail(vaultId))
                        }
                        fetchContent={type => dispatch(fetchContent(type))}
                        postForm={form => dispatch(postForm(form))}
                        searchVault={vaultId => dispatch(searchVault(vaultId))}
                        updateForm={(key, val) =>
                            dispatch(updateForm(key, val))
                        }
                        updateDeckData={json => dispatch(updateDeckData(json))}
                        fileType={eventKey}
                    />
                );

            case 'pdf':
                return (
                    <PdfUploader
                        newCategories={newCategories}
                        languages={locals?.languages}
                        countries={countries}
                        locals={locals}
                        pdfs={documents.filter(doc => isPdf(doc.format__v))}
                        form={form}
                        username={username}
                        downloadRendition={vaultId =>
                            dispatch(downloadDocumentRendition(vaultId))
                        }
                        dereferenceRelationships={id =>
                            dispatch(dereferenceRelationships(id))
                        }
                        fetchContent={type => dispatch(fetchContent(type))}
                        fetchPdfThumbnail={vaultId =>
                            dispatch(fetchPdfThumbnail(vaultId))
                        }
                        postForm={form => dispatch(postForm(form))}
                        searchVault={vaultId => dispatch(searchVault(vaultId))}
                        uploadPdfThumbnail={(vaultId, formData) =>
                            dispatch(uploadPdfThumbnail(vaultId, formData))
                        }
                        updateForm={(key, value) =>
                            dispatch(updateForm(key, value))
                        }
                        updateDeckData={json => dispatch(updateDeckData(json))}
                        tokenize={(id, workerId) =>
                            dispatch(tokenize(id, workerId))
                        }
                        fileType={eventKey}
                    />
                );

            case 'link':
                return <LinkThumbnailUploader />;
            default:
                return (
                    <DeckUploader
                        checkAceDeckIsWideScreen={vaultId =>
                            dispatch(checkAceDeckIsWideScreen(vaultId))
                        }
                        checkVaultDeckIsWideScreen={vaultId =>
                            dispatch(checkVaultDeckIsWideScreen(vaultId))
                        }
                        newCategories={newCategories}
                        countries={countries}
                        locals={locals}
                        languages={locals?.languages}
                        decks={documents.filter(doc =>
                            isPowerpoint(doc.format__v)
                        )}
                        form={form}
                        username={username}
                        dereferenceRelationships={id =>
                            dispatch(dereferenceRelationships(id))
                        }
                        downloadSource={vaultId =>
                            dispatch(downloadDocumentSource(vaultId))
                        }
                        fetchContent={type => dispatch(fetchContent(type))}
                        fetchWorker={id => dispatch(fetchWorker(id))}
                        postForm={form => dispatch(postForm(form))}
                        searchVault={vaultId => dispatch(searchVault(vaultId))}
                        updateForm={(key, value) =>
                            dispatch(updateForm(key, value))
                        }
                        updateDeckData={json => dispatch(updateDeckData(json))}
                        tokenize={(id, workerId) =>
                            dispatch(tokenize(id, workerId))
                        }
                        fileType={eventKey}
                    />
                );
        }
    };

    const Loading = () => (
        <Row>
            <Col
                className='d-flex justify-content-center align-items-center'
                style={{ height: '80vh' }}>
                <Spinner
                    animation='border'
                    role='status'
                    variant='primary'
                    style={{ height: '5rem', width: '5rem' }}>
                    <span className='sr-only'>Loading...</span>
                </Spinner>
            </Col>
        </Row>
    );

    return loading ? (
        <Loading />
    ) : (
        <div>
            <Nav
                variant='tabs'
                onSelect={setTab}
                defaultActiveKey='deck'
                bg='dark'>
                <Nav.Item>
                    <Nav.Link eventKey='deck'>Deck</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey='link'>Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey='pdf'>Pdf</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey='podcast'>Podcast</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey='video'>Video</Nav.Link>
                </Nav.Item>
            </Nav>
            {renderForm(tab)}
        </div>
    );
};

export default Uploader;
