import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import { base64ArrayBuffer, handleError } from '../uploader/helpers';

import { DOC, DOCX, PDF } from './constants';

export const isPdf = docType =>
    isEqual(docType, PDF) || isEqual(docType, DOCX) || isEqual(docType, DOC);

export const isAudio = docType => includes(docType, 'audio');

export const isPowerpoint = docType =>
    isEqual(docType, 'application/vnd.ms-powerpoint') ||
    isEqual(
        docType,
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    );

export const isVideo = docType => includes(docType, 'video');

export { handleError, base64ArrayBuffer };
