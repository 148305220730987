import VideoRect from './VideoRect';
import isEmpty from 'lodash/isEmpty';
import LinkRect from './LinkRect';
import React from 'react';
import PropTypes from 'prop-types';

const CarouselRects = ({
    hasVideo,
    slides,
    selectedSlide,
    getImageWidth,
    handleSelect,
    onLoadedVideoData,
    hasLinks,
    property,
    displayUrl,
    handleSelectLink,
}) => (
    <>
        {hasVideo &&
            slides[selectedSlide]?.video?.map(({ rect, path }, idx) => (
                <VideoRect
                    key={idx}
                    idx={idx}
                    rect={rect}
                    path={path}
                    getImageWidth={getImageWidth}
                    handleSelect={handleSelect}
                    onLoadedVideoData={onLoadedVideoData}
                />
            ))}
        {hasLinks &&
            slides[selectedSlide]?.links?.map(
                ({ url, rect }, idx) =>
                    rect &&
                    !isEmpty(rect) && (
                        <LinkRect
                            key={idx}
                            idx={idx}
                            rect={rect}
                            property={property}
                            url={url}
                            displayUrl={displayUrl}
                            getImageWidth={getImageWidth}
                            handleSelectLink={handleSelectLink}
                        />
                    )
            )}
    </>
);

CarouselRects.propTypes = {
    hasVideo: PropTypes.bool.isRequired,
    slides: PropTypes.array.isRequired,
    selectedSlide: PropTypes.number.isRequired,
    getImageWidth: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    onLoadedVideoData: PropTypes.func.isRequired,
    hasLinks: PropTypes.bool.isRequired,
    property: PropTypes.string.isRequired,
    displayUrl: PropTypes.func.isRequired,
    handleSelectLink: PropTypes.func.isRequired,
};

export default CarouselRects;
