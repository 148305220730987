// # tsunderealtalk#1177
// # tsunderedericktatum

import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@redux-requests/react';
import { useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import { JSONTree } from 'react-json-tree';

import path from 'path';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import uniqueId from 'lodash/uniqueId';

import { fetchRoutes } from '../redux/actions';

const Loading = () => (
    <Row>
        <Col
            className='d-flex justify-content-center align-items-center'
            style={{ height: '80vh' }}>
            <Spinner
                animation='border'
                role='status'
                variant='primary'
                style={{ height: '5rem', width: '5rem' }}>
                <span className='sr-only'>Loading...</span>
            </Spinner>
        </Col>
    </Row>
);

const QueryTest = () => {
    const sessionId = useSelector(state => state.login.data);
    const darkModeSetting = useSelector(state => state.darkMode);
    const [method, setMethod] = useState('get');
    const methods = ['get', 'delete', 'put', 'patch', 'post'];
    const [option, setOption] = useState();
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({});
    const [route, setRoute] = useState('');
    const [roots, setRoots] = useState({});
    const [error, setError] = useState();
    const { data: routesUnformatted } = useQuery({
        type: 'FETCH_ROUTES',
        action: fetchRoutes,
        autoLoad: true,
        multiple: true,
    });

    useEffect(() => {
        console.log('routes: ', routesUnformatted);

        if (!isEmpty(routesUnformatted)) {
            const routes = routesUnformatted.reduce((acc, curr, idx, arr) => {
                acc[curr.path] = {};
                return acc;
            }, {});
            // sort routes into buckets
            Object.keys(routes).forEach(key => {
                const split = key.split('/');
                const root = split[2];
                // fill with routes that include this root
                roots[root] = Object.keys(routes).filter(path =>
                    path.includes(root)
                );
            });

            console.log(roots);

            const initialRoute = Object.keys(roots)[0];
            const initialOption = roots[initialRoute][0];

            setState(routes);
            setRoots(roots);

            setRoute(initialRoute);
            setOption(initialOption);

            resetParams(initialOption);
        }
    }, [routesUnformatted]);

    useEffect(() => {
        console.log('setting params...');

        if (option) {
            resetParams(option);
            console.log('params: ', params);
        }
    }, [option]);

    const fetch = useCallback(
        async route => {
            try {
                setLoading(true);

                const assignVariables = () => {
                    let newRoute = route.split('/');
                    console.log('split route: ', newRoute);

                    const contentIdx = findIndex(
                        newRoute,
                        e => e === 'content'
                    );
                    const isContentRoute = contentIdx !== -1;
                    // NOTE: need to handle special case for content route
                    console.log('is content route?: ', contentIdx !== -1);

                    if (isContentRoute) {
                        newRoute = [
                            ...newRoute.slice(0, contentIdx + 1),
                            'all',
                            ...newRoute.slice(contentIdx + 1, newRoute.length),
                        ];
                    }

                    const paramList = Object.entries(params);

                    console.log('params: ', paramList);
                    console.log('option:', option);

                    paramList.forEach(([key, val]) => {
                        newRoute.forEach((_route, idx) => {
                            if (_route === key) {
                                newRoute[idx] = val;
                            }
                        });
                    });

                    // NOTE: again we need to handle the content route case
                    // where we drop the contentType and 'all' if we have a vaultId

                    if (isContentRoute) {
                        if (!isEmpty(last(newRoute))) {
                            const contentTypeIdx =
                                findIndex(newRoute, e => e === 'all') + 1;
                            newRoute = [
                                ...newRoute.slice(0, contentTypeIdx - 1),
                                ...newRoute.slice(
                                    contentTypeIdx + 1,
                                    newRoute.length
                                ),
                            ];
                        }
                    }

                    const result = path.normalize(newRoute.join('/'));

                    console.log('route: ', result);
                    return result;
                };

                const setAuthHeaders = (sessionId, config = {}) => {
                    return {
                        ...config,
                        headers: {
                            ...config.headers,
                            Authorization: sessionId,
                        },
                    };
                };

                const routeWithParams = assignVariables();
                const config = route.includes('vault')
                    ? setAuthHeaders(sessionId)
                    : {};

                console.log(method);
                const { data: response } = await axios[method](
                    routeWithParams,
                    config
                );

                console.log('response:', response);

                setState({ [route]: get(response, 'data', response) });
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError(err);
            }
        },
        [params, option, method]
    );

    const resetParams = route => {
        const split = route.split('/');
        const newParams = {};
        for (let i = 0; i < split.length; i++) {
            if (split[i].includes(':')) {
                newParams[split[i]] = '';
            }
        }
        setParams(newParams);
    };

    const selectRoot = useCallback(
        root => {
            const opt = roots[root][0];
            setRoute(root);
            setOption(opt);

            setParams({});
        },
        [routesUnformatted]
    );

    const getRoot = option => {
        let found;
        Object.keys(roots).forEach(root => {
            if (roots[root].includes(option)) {
                found = root;
            }
        });
        return found;
    };

    return (
        <Container>
            <Card className='justify-content-md-center'>
                <Card.Header>
                    <InputGroup className='mb-3'>
                        <DropdownButton title={route.toUpperCase()}>
                            {Object.keys(roots).map(root => (
                                <Dropdown.Item
                                    key={uniqueId(`root-${root}`)}
                                    variant='secondary'
                                    onClick={() => selectRoot(root)}>
                                    {root}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                        {roots[getRoot(option)] && (
                            <DropdownButton title={option}>
                                {roots[getRoot(option)].map(route => (
                                    <Dropdown.Item
                                        key={uniqueId(route)}
                                        variant='secondary'
                                        onClick={() => setOption(route)}>
                                        {route}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        )}

                        <DropdownButton title={method.toUpperCase()}>
                            {methods.map(_method => (
                                <Dropdown.Item
                                    key={uniqueId(_method)}
                                    variant='secondary'
                                    onClick={() => setMethod(_method)}>
                                    {_method}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                        {!isEmpty(params) && (
                            <InputGroup.Text>Params</InputGroup.Text>
                        )}
                        {!isEmpty(params) &&
                            Object.keys(params).map(param => (
                                <>
                                    <InputGroup.Text>{param}</InputGroup.Text>
                                    <FormControl
                                        onChange={e =>
                                            setParams({
                                                ...params,
                                                [param]: e.target.value,
                                            })
                                        }
                                    />
                                </>
                            ))}
                        <Button onClick={() => fetch(option)}>Send</Button>
                    </InputGroup>
                </Card.Header>
                <Card.Body>
                    {loading ? (
                        <Loading />
                    ) : (
                        <JSONTree
                            data={state[option]}
                            theme={{ scheme: 'solarized' }}
                            invertTheme={!darkModeSetting}
                        />
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
};

export default QueryTest;
