import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

const LanguageSelector = ({
    disabled,
    onChange,
    selected,
    locals,
    readOnly,
}) => {
    return (
        <Form.Group as={Col} controlId='language'>
            <Form.Label>Language</Form.Label>
            <Form.Control
                custom
                name='language'
                as='select'
                onChange={onChange}
                value={selected}
                disabled={disabled}
                readOnly={readOnly}>
                {locals?.languages &&
                    locals.languages.map(lang => (
                        <option key={lang.id}>{lang.name}</option>
                    ))}
            </Form.Control>
        </Form.Group>
    );
};

LanguageSelector.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    locals: PropTypes.object,
};
LanguageSelector.defaultProps = {
    disabled: false,
    readOnly: false,
    locals: { languages: [] },
};

export default LanguageSelector;
