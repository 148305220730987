import React from 'react';
import Form from 'react-bootstrap/Form';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';

const transitionTypes = [
    'none',
    'fade',
    'vertical-push',
    'horizontal-wipe',
    'circle-out',
];

const TransitionSelector = ({
    disabled,
    onChange,
    selected,
    readOnly,
    ...props
}) => {
    return (
        <Form.Group {...props} controlId='transition'>
            <Form.Label>Transition Type</Form.Label>
            <Form.Control
                name='transition'
                as='select'
                onChange={onChange}
                value={selected}
                disabled={disabled}
                readOnly={readOnly}>
                {transitionTypes &&
                    transitionTypes.map((type, index) => (
                        <option key={index} value={type}>
                            {capitalize(type)}
                        </option>
                    ))}
            </Form.Control>
        </Form.Group>
    );
};

TransitionSelector.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
};

TransitionSelector.defaultProps = {
    disabled: false,
    readOnly: false,
};

export default TransitionSelector;
