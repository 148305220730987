import { computeVideoRect } from './helpers/videos';
import React from 'react';
import PropTypes from 'prop-types';

const LinkRect = ({
    idx,
    rect,
    property,
    url,
    displayUrl,
    getImageWidth,
    handleSelectLink,
}) => (
    <div
        tabIndex={idx}
        key={idx}
        style={{
            ...computeVideoRect(rect, getImageWidth()),
            border: '5px solid yellow',
            position: 'absolute',
            textAlign: 'center',
            color: 'yellow',
            backgroundColor: 'grey',
            opacity: 0.5,
            display: 'flex',
            fontWeight: 'bold',
            cursor: property === 'links' ? 'pointer' : 'unset',
        }}
        onClick={e => handleSelectLink(e, idx)}>
        <p className='m-auto'>{displayUrl(url)}</p>
    </div>
);

LinkRect.propTypes = {
    idx: PropTypes.number.isRequired,
    rect: PropTypes.object.isRequired,
    property: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    displayUrl: PropTypes.func.isRequired,
    getImageWidth: PropTypes.func.isRequired,
    handleSelectLink: PropTypes.func.isRequired,
};

export default LinkRect;
