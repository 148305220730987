import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Container from 'react-bootstrap/Container';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { stringify } from 'qs';

import {
    fetchAllVaultDocuments,
    fetchContent,
    fetchVaultLinks,
    searchVault,
} from '../redux/actions';

import DeckTypeahead from '../components/uploader/DeckTypeahead';

const VaultTest = () => {
    const dispatch = useDispatchRequest();
    const history = useHistory();
    const [domain, setDomain] = useState(
        'sb-abiomed-promomats-qa.veevavault.com'
    );
    const [vaultId, setVaultId] = useState('');
    const [query, setQuery] = useState('');
    const [json, setJson] = useState({});
    const [toggleLinks, setToggleLinks] = useState(false);

    const { data: sessionId, username } = useSelector(state => state.login);
    const { data: content } = useQuery({
        type: 'FETCH_CONTENT',
        action: fetchContent,
        multiple: true,
        autoLoad: true,
    });
    const { data: links } = useQuery({
        type: 'FETCH_VAULT_LINKS',
        action: fetchVaultLinks,
        autoLoad: true,
        multiple: true,
    });
    const { data: documents } = useQuery({
        type: 'FETCH_VAULT_ALL_DOCUMENTS',
        action: fetchAllVaultDocuments,
        autoLoad: true,
        multiple: true,
    });

    useEffect(() => {
        const params = new URLSearchParams(query);
        const _json = {};

        for (const p of params.keys()) {
            _json[p] = params.get(p);
        }

        setJson(_json);
    }, [query]);

    const loadParams = component => {
        history.push(`/vault/${component}?${query}`);
    };

    const onChange = async ([value]) => {
        if (!value) return;

        const newVaultId = toggleLinks
            ? get(value, ['name__v'])
            : get(value, ['document_number__v']);

        setVaultId(newVaultId);

        if (toggleLinks) {
            const newQuery = stringify({
                vaultId: value.id,
                docName: value.name__v,
                vaultDomain: domain,
                sessionId,
                username,
            });
            setQuery(newQuery);
        } else {
            const { data } = await dispatch(searchVault(newVaultId));
            const doc = get(data, 'document');

            const newQuery = stringify({
                vaultId: doc.document_number__v,
                docName: doc.name__v,
                vaultDomain: domain,
                sessionId,
                username,
            });
            setQuery(newQuery);
        }
    };

    const canRemove = find(content, el => el.vaultId === vaultId);

    return (
        <Container>
            <h3>Query:</h3>
            <pre
                style={{
                    backgroundColor: '#6c757d',
                    width: '100%',
                    borderRadius: '0.25rem',
                }}>
                {JSON.stringify(json, null, 3)}
            </pre>
            <DeckTypeahead
                decks={toggleLinks ? links : documents}
                onChange={onChange}
                selected={vaultId}
                labelKey={toggleLinks ? 'name__v' : 'document_number__v'}
                placeholder={toggleLinks ? 'Enter Link ID' : 'Enter Vault ID'}
            />
            <p>Entity:</p>
            <ToggleButtonGroup
                name='radio'
                type='radio'
                defaultValue={toggleLinks}
                aria-label='links toggle'
                style={{ width: '100%', marginBottom: '8px' }}>
                <ToggleButton
                    variant='secondary'
                    value={false}
                    onChange={() => setToggleLinks(false)}>
                    Documents
                </ToggleButton>
                <ToggleButton
                    variant='secondary'
                    value={true}
                    onChange={() => setToggleLinks(true)}>
                    Links
                </ToggleButton>
            </ToggleButtonGroup>
            <p>Component:</p>
            <Button
                block
                disabled={toggleLinks || isEmpty(json)}
                onClick={() => loadParams('uploader')}>
                Deck Uploader
            </Button>
            <Button
                block
                disabled={toggleLinks || isEmpty(json) || !!canRemove}
                onClick={() => loadParams('remover')}>
                Deck Remover
            </Button>
            <Button
                block
                disabled={!toggleLinks || isEmpty(json)}
                onClick={() => loadParams('links')}>
                Link Thumbnail Uploader
            </Button>
        </Container>
    );
};

export default VaultTest;
