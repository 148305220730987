import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, Col, Form } from 'react-bootstrap';

const CopyLinksComponent = ({
    isDeckLoaded,
    slides,
    sourceSlideId,
    copyLinksToSlides,
    setSlides,
}) => {
    const [selected, setSelected] = useState([]);

    const handleButtonClick = () => {
        const sourceSlide = slides.find(slide => slide.id === sourceSlideId);
        const selectedSlideIds = selected.map(slide => slide.id);
        const updatedSlides = copyLinksToSlides(
            sourceSlide,
            selectedSlideIds,
            slides
        );
        setSlides(updatedSlides);
    };

    return (
        <Form.Row>
            <Form.Group as={Col}>
                <Form.Label>Copy Links To</Form.Label>
                <Form.Row>
                    <Form.Group as={Col}>
                        <div className='copy-links-selector'>
                            <Typeahead
                                id='slide-select'
                                multiple
                                onChange={setSelected}
                                options={slides}
                                labelKey='id'
                                placeholder='Select slides...'
                                selected={selected}
                                disabled={!isDeckLoaded}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group as={Col} xs={2}>
                        <Button
                            variant='primary'
                            onClick={handleButtonClick}
                            disabled={!isDeckLoaded}
                            style={{
                                height: '38px',
                                width: '100%',
                            }}>
                            Copy
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form.Group>
        </Form.Row>
    );
};

CopyLinksComponent.propTypes = {
    isDeckLoaded: PropTypes.bool.isRequired,
    slides: PropTypes.array.isRequired,
    sourceSlideId: PropTypes.string.isRequired,
    copyLinksToSlides: PropTypes.func.isRequired,
    setSlides: PropTypes.func.isRequired,
};

export default CopyLinksComponent;
