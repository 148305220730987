import { css } from 'aphrodite';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import styles from './styles';

const DeckUploaderHelp = ({ show, handleClose }) => {
    const [url, setUrl] = useState();

    return (
        <Modal show={show} size='lg'>
            <Modal.Header>
                <Modal.Title>Steps to upload a Pdf:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <ol>
                            <li
                                className={css(styles.listItem)}
                                onMouseOver={() =>
                                    setUrl(
                                        require('../../../public/images/vaultIdform.png')
                                    )
                                }>
                                Type the Vault ID of the document you wish to
                                upload to ACE Pro
                            </li>
                            <li>
                                Select the document from autocomplete dropdown,
                                the pdf will then automatically download
                            </li>
                            <li>
                                Once the pdf is downloaded, the first page will
                                appear in the "preview" card
                            </li>
                            <li>
                                Click and drag to highlight the area you wish to
                                make into a thumbnail
                            </li>
                            <li
                                className={css(styles.listItem)}
                                onMouseOver={() =>
                                    setUrl(
                                        require('../../../public/images/8upload.png')
                                    )
                                }>
                                Click <code>upload</code> button
                            </li>
                            <li>
                                Go to the tasks tab to view the upload progress
                            </li>
                        </ol>
                    </Col>
                    <Col>
                        <img
                            className='img-fluid'
                            src={
                                url ||
                                require('../../../public/images/missing_1730_1.png')
                            }
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' block onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeckUploaderHelp;
