import { css } from 'aphrodite';
import styles from './styles';
import { computeVideoRect, getVideoPath } from './helpers/videos';
import React from 'react';
import PropTypes from 'prop-types';

export const VideoRect = ({
    idx,
    rect,
    path,
    getImageWidth,
    handleSelect,
    onLoadedVideoData,
}) => (
    <video
        key={idx}
        className={css(styles.video)}
        style={{
            ...computeVideoRect(rect, getImageWidth()),
            cursor: 'pointer',
        }}
        autoPlay
        muted
        playsInline
        loop
        src={getVideoPath(path)}
        onLoadedData={onLoadedVideoData}
        onClick={e => handleSelect(e, idx)}></video>
);

VideoRect.propTypes = {
    idx: PropTypes.number.isRequired,
    rect: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    getImageWidth: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    onLoadedVideoData: PropTypes.func.isRequired,
};

export default VideoRect;
