import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

const DeckUploaderHelp = ({ show, handleClose }) => {
    const [url, setUrl] = useState();

    return (
        <Modal show={show} size='lg'>
            <Modal.Header>
                <Modal.Title>Steps to upload a video:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <ol>
                            <li>
                                Type the Vault ID of the video you wish to
                                upload to ACE Pro
                            </li>
                            <li>
                                Select the document from autocomplete dropdown,
                                the video will then automatically download
                            </li>
                            <li>
                                Once the video is downloaded, a download button
                                will appear on the autocomplete input
                            </li>
                            <li>
                                Click <code>download</code> and save the file to
                                you computer
                            </li>
                            <li>
                                Edit the video file as necessary (do not
                                compress, this will lead to a severe loss in
                                quality! ACE Loader automatically compresses on
                                upload)
                            </li>
                            <li>
                                Click <code>browse</code> in the file browser
                                and select your video file
                            </li>
                            <li>
                                For embedded videos, type in an appropriate
                                title (this will be the video's filename)
                            </li>
                            <li>
                                Click <code>upload</code>
                            </li>
                            <li>
                                Go to the tasks tab to view the upload progress
                            </li>
                        </ol>
                    </Col>
                    <Col>
                        <img
                            className='img-fluid'
                            src={
                                url ||
                                require('../../../public/images/missing_1730_1.png')
                            }
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' block onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeckUploaderHelp;
