import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatchRequest } from '@redux-requests/react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import get from 'lodash/get';

import {
    login,
    updateLoginForm,
    updateSessionId,
    validateSession,
} from '../redux/actions';

import { handleError } from '../components/uploader/helpers';

const Login = () => {
    const dispatch = useDispatchRequest();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const { username, password } = useSelector(state => state.login);

    const onChange = ({ currentTarget: { value, name } }) => {
        dispatch(updateLoginForm(name, value));
    };

    const submit = async () => {
        try {
            setLoading(true);
            const { data: sessionId, error } = await dispatch(
                login({ username, password })
            );

            if (error) {
                throw new Error(get(error, 'response.data.error.message'));
            }

            if (sessionId) {
                const { data: valid } = await dispatch(
                    validateSession(sessionId)
                );

                if (valid === 'valid') {
                    updateSessionId(sessionId);
                    setLoading(false);
                    history.push('/');
                } else {
                    throw new Error('Invalid session ID');
                }
            }
        } catch (err) {
            setError(handleError(err));
            setLoading(false);
        }
    };

    return (
        <Container>
            {error && (
                <Alert
                    variant='danger'
                    dismissible
                    onClose={() => setError(false)}>
                    {error}
                </Alert>
            )}

            <Row className='h-75'>
                <Card
                    className='d-block mx-auto my-auto'
                    style={{ width: '40rem' }}>
                    <Card.Body>
                        <Form>
                            <Card.Img
                                variant='top'
                                src={require('../../public/images/vault-login-logo.svg')}
                            />

                            <Card.Title>
                                Login with your Veeva Vault credentials
                            </Card.Title>
                            <Form.Group controlId='email'>
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type='email'
                                    placeholder='Enter email'
                                    name='username'
                                    onChange={onChange}
                                    value={username}
                                />
                            </Form.Group>
                            <Form.Group controlId='password'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type='password'
                                    placeholder='Password'
                                    name='password'
                                    onChange={onChange}
                                    value={password}
                                />
                            </Form.Group>
                            <Button
                                variant='primary'
                                onClick={async () => await submit()}>
                                Login
                                {loading && (
                                    <Spinner
                                        as='span'
                                        animation='grow'
                                        role='status'
                                        aria-hidden='true'
                                        size='sm'
                                    />
                                )}
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
};

export default Login;
