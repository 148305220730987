import path from 'path';
import JSZip from 'jszip';
import find from 'lodash/find';
import first from 'lodash/first';
import has from 'lodash/has';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import some from 'lodash/some';
import uniq from 'lodash/uniq';

const STATUS_OK = 200;
const STATUS_FORBIDDEN = 403;
const MAX_RETRIES = 1;

function isValidStatusCode(code) {
    return code >= 100 && code <= 599;
}

function getStatusCode(obj) {
    if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
            if (key === 'status' && isValidStatusCode(obj[key])) {
                return obj[key];
            }
            const result = getStatusCode(obj[key]);
            if (result) {
                return result;
            }
        }
    } else if (typeof obj === 'number' && isValidStatusCode(obj)) {
        return obj;
    } else if (typeof obj === 'string') {
        const regex = /Request failed with status code (\d{3})/;
        const match = obj.match(regex);
        if (match && match[1]) {
            const numCode = parseInt(match[1], 10);
            if (isValidStatusCode(numCode)) {
                return numCode;
            }
        }
    }
    return null;
}

async function postFormAndFetchContent(formData, postForm, fetchContent) {
    try {
        const retryResponse = await postForm(formData);
        const retryError = get(retryResponse, 'error', retryResponse);
        const retryStatus = getStatusCode(retryError);

        if (retryStatus === STATUS_OK) {
            await fetchContent('PRS');
            return { loading: false, done: true };
        } else {
            throw new Error('Error uploading zip, make sure your zip is valid');
        }
    } catch (err) {
        console.error('Failed in retry:', err);
        throw new Error('Error in retry mechanism');
    }
}

export async function handleResponse(formData, response) {
    console.log('Initial response:', response);

    const error = get(response, 'error', response);
    const status = getStatusCode(error);

    console.log('Extracted error:', error);
    console.log('Extracted status code:', status);

    if (status) {
        console.log(`Status code exists: ${status}`);

        if (status === STATUS_FORBIDDEN) {
            console.log('Status is Forbidden. Starting retry mechanism.');

            for (let i = 0; i < MAX_RETRIES; i++) {
                try {
                    console.log(`Retry attempt ${i + 1}`);
                    const zipBuffer = await formData.get('zip').arrayBuffer();
                    const contents = await unzip(zipBuffer); // Assuming unzip is defined elsewhere

                    const newZip = new File(
                        [await zip(formData.get('vaultId'), contents)], // Assuming zip is defined elsewhere
                        `${formData.get('vaultId')}.zip`,
                        { type: formData.get('zip').type }
                    );

                    formData.set('zip', newZip);

                    const newState = await postFormAndFetchContent(
                        formData,
                        this.props.postForm,
                        this.props.fetchContent
                    );
                    this.setState(newState);
                    console.log('Retry successful. Exiting loop.');
                    return; // Success, exit loop and function
                } catch (err) {
                    console.error(
                        `Retry ${i + 1} failed, will try again:`,
                        err
                    );
                }
            }

            console.error('All retries failed.');
            throw new Error('All retries failed. Make sure your zip is valid.');
        } else if (status === STATUS_OK) {
            console.log('Status is OK. Fetching content.');

            await this.props.fetchContent('PRS');
            this.setState({ loading: false, done: true });
            console.log('State set to loading: false, done: true');
        } else {
            console.error(`Unhandled status code: ${status}`);
            throw new Error(`Unhandled status code: ${status}`);
        }
    } else {
        console.error("Couldn't determine the status code.");
        throw new Error("Couldn't determine the status code");
    }
}

export const validateZip = async content => {
    try {
        const newZip = new JSZip();
        const zip = await newZip.loadAsync(content);

        return !!zip;
    } catch (err) {
        return false;
    }
};

export const zip = async (vaultId, zipContents) => {
    const newZip = new JSZip();
    zipContents.forEach(file =>
        newZip.folder(vaultId).file(file.name, file.data)
    );
    const result = await newZip.generateAsync({ type: 'blob' });
    return result;
};

export const unzip = async zipContents => {
    const jszip = new JSZip();
    const zipFile = await jszip.loadAsync(zipContents);

    const files = Object.keys(zipFile.files).map(async key => {
        const file = zipFile.files[key];
        const png = await zipFile.file(file.name).async('blob');
        return { name: path.basename(file.name), data: png };
    });
    return Promise.all(files);
};

export const removeEmojis = input => {
    const withEmojis = /\p{Extended_Pictographic}/u;
    const result = input.replace(withEmojis, '');
    return result;
};

export const stringToArrayBuffer = str => new TextEncoder().encode(str);

export const arrayBufferToString = arrayBuffer =>
    new TextDecoder().decode(arrayBuffer);

export function base64ArrayBuffer(arrayBuffer, appendPngData = true) {
    let base64 = '';
    const encodings =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    const bytes = new Uint8Array(arrayBuffer);
    const byteLength = bytes.byteLength;
    const byteRemainder = byteLength % 3;
    const mainLength = byteLength - byteRemainder;

    let a;
    let b;
    let c;
    let d;
    let chunk;

    // Main loop deals with bytes in chunks of 3
    for (let i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
        d = chunk & 63; // 63       = 2^6 - 1

        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder === 1) {
        chunk = bytes[mainLength];

        a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

        // Set the 4 least significant bits to zero
        b = (chunk & 3) << 4; // 3   = 2^2 - 1

        base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder === 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

        a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

        // Set the 2 least significant bits to zero
        c = (chunk & 15) << 2; // 15    = 2^4 - 1

        base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }

    if (appendPngData) {
        return `data:image/png;base64,${base64}`;
    }

    return base64;
}

export function handleError(error) {
    if (has(error, 'error')) {
        return handleError(get(error, 'error'));
    }

    if (has(error, 'type')) {
        const type = get(error, 'type', '');
        if (type?.includes('ABORT')) {
            return type;
        }
    }

    if (has(error, 'response')) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.error(error.response.data.error);
        // console.error(error.response.status);
        // console.error(error.response.headers);
        const data = get(error, 'response.data', '');
        if (typeof data === 'string') {
            return data;
        } else if (has(data, 'error')) {
            return get(data, 'error');
        } else {
            return JSON.stringify(data);
        }
    } else if (has(error, 'request')) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        const response = get(error, 'request.response');

        if (typeof response === 'string') {
            return get(JSON.parse(response), 'error');
        } else {
            return get(response, 'error');
        }
    } else if (has(error, 'message')) {
        // Something happened in setting up the request that triggered an Error
        return get(error, 'message');
    } else {
        return JSON.stringify(error);
    }
}

// NOTE: if US is in the content zone then the language is English (US)
// multiple countries search for US, then its a US content zone otherwise its the first one
export function getLanguage(lang) {
    if (lang === 'English') {
        return 'English (US)';
    }

    return lang;
}

export function getLanguageCode(lang) {
    const { languages } = this.props;

    for (const key in languages) {
        if (languages[key].name === getLanguage(lang)) {
            return languages[key].code.toUpperCase();
        }
    }
    return '';
}

export function checkContentZone(countryIds, region) {
    const { countries: countryCodes } = this.props;

    return some(countryIds, countryId =>
        includes(
            region,
            find(countryCodes, { id: countryId })?.abbreviation__vs
        )
    );
}

export function getContentZone(countryIds) {
    // NOTE: search array for US or global
    // 00C000000000101

    const middleEast = ['UAE', 'EG', 'KW', 'SA', 'IL'];
    const asiaPacific = ['CN', 'TW', 'SG', 'KR', 'IN', 'AU', 'NZ'];

    const us = ['US', 'CA'];
    const lat = ['LAT', 'AR', 'BR', 'MX', 'CL', 'PA'];

    if (checkContentZone.call(this, countryIds, us)) {
        return 'US';
    } else if (checkContentZone.call(this, countryIds, lat)) {
        return 'LAT';
    } else if (find(countryIds, o => o === '00C000000000115')) {
        return 'JPN';
    } else if (checkContentZone.call(this, countryIds, middleEast)) {
        return 'ME';
    } else if (checkContentZone.call(this, countryIds, asiaPacific)) {
        return 'APAC';
    } else {
        return 'EU';
    }
}

export function getCountryCode(countryIds) {
    const { countries: countryCodes } = this.props;

    // NOTE: make this return US if the country code is in latin america

    const dereferencedCodes =
        countryIds.map(countryId => {
            const item = find(countryCodes, { id: countryId });

            if (item) {
                return item.abbreviation__vs;
            } else {
                throw new Error(
                    `Error: Can't dereference country id ${countryId}`
                );
            }
        }) || [];

    return uniq(dereferencedCodes);
}

export function getKeywords(keywords = '') {
    return keywords.split(';').join(',');
}

export function dereferenceNewCategory(category) {
    const { newCategories } = this.props;

    const dereferencedCategory = find(newCategories, { id: category });
    return get(dereferencedCategory, 'name__v', '');
}

export function getExposure(doc) {
    if (get(doc, 'document_number__v').includes('DAM')) {
        return 'external';
    } else if (
        !isNull(get(doc, 'ace_external_main_category__c', null)) &&
        !isNull(get(doc, 'ace_internal_main_category__c', null))
    ) {
        return 'all';
    } else if (
        isNull(get(doc, 'ace_external_main_category__c', null)) &&
        isNull(get(doc, 'ace_internal_main_category__c', null)) &&
        !isNull(get(doc, 'ace_pod_category__c', null))
    ) {
        // RCL 7/31/23
        // Could possibly also do this when available_locations1__c = ['ACE Pod'], but I'm not sure if it's always the available_locations1__c
        // or possibly just available_locations__c certain scenarios
        return 'ace-pod';
    } else if (isNull(get(doc, 'ace_external_main_category__c', null))) {
        return 'internal';
    } else if (isNull(get(doc, 'ace_internal_main_category__c', null))) {
        return 'external';
    }
}

export function printCategory(mainCategory, secondCategory, thirdCategory) {
    let categoryPath = mainCategory;

    if (!isEmpty(secondCategory) && secondCategory !== 'N/A') {
        categoryPath += ` > ${secondCategory}`;
        if (!isEmpty(thirdCategory) && thirdCategory !== 'N/A') {
            categoryPath += ` > ${thirdCategory}`;
        }
    }
    return categoryPath;
}

export const getRelatedItems = async function (id) {
    const { dereferenceRelationships } = this.props;
    const { data: relatedItems } = await dereferenceRelationships(id);
    console.log('relatedItems: ', relatedItems);
    return relatedItems.join(',');
};

export async function populateFormWithVaultData(vaultId) {
    const {
        searchVault,
        updateForm,
        tokenize,
        fetchPdfThumbnail,
        fetchPodcastThumbnail,
    } = this.props;

    try {
        const { data } = await searchVault(vaultId);

        if (isEmpty(data.document)) {
            throw new Error("couldn't find vault document");
        }

        const documentData = data.document;
        const fileName = get(documentData, 'filename__v');
        const id = get(documentData, 'id');
        const documentDetails = {
            countryCodes: getCountryCode.call(this, documentData.country__v),
            contentZone: getContentZone.call(this, documentData.country__v),
            languageCode: getLanguageCode.call(
                this,
                first(documentData.language__v)
            ),
            aceProDescriptor: get(documentData, 'ace_pro_descriptor__c', ''),
            mainCategory: dereferenceNewCategory.call(
                this,
                get(documentData, 'ace_dam_category__c')
            ),
            secondCategory: dereferenceNewCategory.call(
                this,
                get(documentData, 'ace_dam_subcategory__c')
            ),
            exposure: getExposure.call(this, documentData),
            internalMainCategory: dereferenceNewCategory.call(
                this,
                get(documentData, 'ace_internal_main_category__c')
            ),
            internalSecondCategory: dereferenceNewCategory.call(
                this,
                get(documentData, 'ace_internal_secondary_category__c')
            ),
            internalThirdCategory: dereferenceNewCategory.call(
                this,
                get(documentData, 'ace_internal_tertiary_category__c')
            ),
            externalMainCategory: dereferenceNewCategory.call(
                this,
                get(documentData, 'ace_external_main_category__c')
            ),
            externalSecondCategory: dereferenceNewCategory.call(
                this,
                get(documentData, 'ace_external_secondary_category__c')
            ),
            externalThirdCategory: dereferenceNewCategory.call(
                this,
                get(documentData, 'ace_external_tertiary_category__c')
            ),
            documentId: id,
            relatedItems: await getRelatedItems.call(this, id),
            title: get(documentData, 'name__v', documentData.title__v),
            fileName: fileName,
            keywords: getKeywords(documentData.keywords__c) || '',
            language: getLanguage.call(this, first(documentData.language__v)),
            qpa: String(
                documentData.quarterly_plan_of_action__c === 'true' ||
                    documentData.quarterly_plan_of_action__c === true
            ),
            wistiaUrl: get(documentData, 'production_wistia_url__c', false),
        };

        updateForm(documentDetails);

        const fileType = path.extname(fileName);
        if (
            isFunction(this.props.tokenize) &&
            ['.pdf', '.docx', '.doc', '.pptx', '.ppt'].includes(fileType)
        ) {
            await tokenize({ id, vaultId, contentType: fileType });
        }

        let image;
        if (['.pdf', '.docx', '.doc'].includes(fileType)) {
            const { data: imageData } = await fetchPdfThumbnail(vaultId);
            image = imageData;
        } else if (['.wav', '.mp3'].includes(fileType)) {
            const { data: imageData } = await fetchPodcastThumbnail(id);
            image = imageData;
        }

        if (image) {
            const imageData = base64ArrayBuffer(image);
            if (imageData !== 'data:image/png;base64,') {
                updateForm({ image: imageData });
                this.setState({ pdfThumbnail: imageData });
            }
        }

        return documentData;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function populatePermissionsAndStatus(docType, vaultId) {
    const {
        updateForm,
        fetchContent,
        checkAceDeckIsWideScreen,
        checkVaultDeckIsWideScreen,
    } = this.props;
    const { isIbr } = this.state;

    try {
        const { data: content } = await fetchContent(vaultId);
        const found = first(content);

        if (
            !found ||
            found.status === 'not found' ||
            found.contentType !== 'PRS'
        ) {
            updateForm({
                grouped: false,
                animated: false,
                status: 'live',
                permissions: isIbr ? 'IBR' : 'pub',
                operation: 'create',
                transition: 'none',
            });
            this.setState({
                mode: 'deploy',
                deckExists: false,
                warnAspectRatio: false,
            });
            return;
        }

        console.log('found: ', found);

        const widescreenData = await Promise.all([
            checkAceDeckIsWideScreen(vaultId),
            checkVaultDeckIsWideScreen(vaultId),
        ]);

        const resizeRects = !widescreenData[0].data && widescreenData[1].data;

        console.log('oldDeckIsWideScreen: ', widescreenData[0].data);
        console.log('newDeckIsWideScreen: ', widescreenData[1].data);

        updateForm('resizeRects', resizeRects);
        updateForm({
            grouped: found.grouped ?? false,
            animated: found.animated === 1,
            status: 'live',
            permissions: isIbr ? 'IBR' : found.permissions ?? 'pub',
            operation: 'update',
            transition: found.transition ?? 'none',
        });
        this.setState({
            mode: 'deploy',
            deckExists: true,
            warnAspectRatio: resizeRects,
            disableImageMatching: resizeRects,
        });
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export function onChange({
    currentTarget: { checked, name, type, value, files, selectedOptions },
}) {
    const { updateForm } = this.props;

    if (type === 'checkbox') {
        updateForm(name, checked);
    } else if (type === 'select-one') {
        updateForm(name, value);
    } else if (type === 'select-multiple') {
        const values = Array.from(selectedOptions).map(o => o.value);
        updateForm(name, values);
    } else if (files && files.length > 0) {
        updateForm(name, files[0]);
    } else {
        updateForm(name, value);
    }
}
