import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import axios from 'axios';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';
import rootSaga from '../sagas';
import formReducer from '../reducers/form';
import darkModeReducer from '../reducers/darkMode';
import loginReducer from '../reducers/login';
import progressReducer from '../reducers/progress';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['login', 'darkMode'],
};

export const configureStore = () => {
    const { requestsReducer, requestsMiddleware } = handleRequests({
        driver: createDriver(axios),
        cache: true,
        ...rootSaga,
    });

    const reducers = combineReducers({
        darkMode: darkModeReducer,
        form: formReducer,
        progress: progressReducer,
        login: loginReducer,
        requests: requestsReducer,
    });

    const persistedReducer = persistReducer(persistConfig, reducers);

    const composeEnhancers =
        (typeof window !== 'undefined' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;

    const store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(...requestsMiddleware, logger))
    );

    const persistor = persistStore(store);

    return { store, persistor };
};
