import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const defaultProps = {
    color: 'skyblue',
    height: 4,
};

const PageProgress = ({ color, height }) => {
    const [width, setWidth] = useState('0%');
    const progress = useSelector(state => state.progress);

    let timer;

    const watchProgress = () => {
        const loaded = `${(progress.loaded / progress.total) * 100}%`;
        setWidth(loaded);
        if (loaded === '100%') {
            timer = setTimeout(() => setWidth('0%'), 1000);
        }
    };

    useEffect(() => {
        watchProgress();
        if (timer) {
            return () => {
                clearTimeout(timer);
            };
        }
    }, [progress]);

    const styles = {
        marginTop: 0,
        padding: 0,
        background: color,
        position: 'fixed',
        height: height,
        width: width,
        top: 0,
        zIndex: 99,
        transition: 'width 200ms ease-out',
    };

    return <div style={styles} />;
};

PageProgress.defaultProps = defaultProps;

PageProgress.propTypes = {
    color: PropTypes.string,
    height: PropTypes.number,
};

export default PageProgress;
