import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

const ContentZoneSelector = ({ disabled, onChange, selected, readOnly }) => (
    <Form.Group as={Col} controlId='contentZone'>
        <Form.Label>Content Zone</Form.Label>
        <Form.Control
            custom
            name='contentZone'
            as='select'
            onChange={onChange}
            value={selected}
            disabled={disabled}
            readOnly={readOnly}>
            <option>US</option>
            <option>EU</option>
            <option>ME</option>
            <option>APAC</option>
            <option>JPN</option>
            <option>LAT</option>
        </Form.Control>
    </Form.Group>
);

ContentZoneSelector.defaultProps = {
    disabled: false,
    readOnly: false,
};

ContentZoneSelector.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
};

export default ContentZoneSelector;
