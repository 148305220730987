import has from 'lodash/has';
import get from 'lodash/get';
import set from 'lodash/set';

import {
    addLink,
    clear,
    clearAll,
    downloadJson,
    getDeckJson,
    getDefaultRect,
    getRect,
    getScale,
    manuallyUpdateJson,
    nextSlide,
    onImageLoaded,
    preloadImages,
    prevSlide,
    restoreOldVals,
    restoreRect,
    setBackupSlide,
    setScale,
    setSlideJson,
    submit,
    uploadJson,
} from './index';

import { handleError } from '../../uploader/helpers';
import cloneDeep from 'lodash/cloneDeep';

export const setCrop = function () {
    const { slides, selectedSlide, arrayNumber } = this.state;

    if (has(slides, [selectedSlide, 'links'])) {
        const scale = getScale.call(this);
        const rect = get(
            slides,
            [selectedSlide, 'links', arrayNumber, 'rect'],
            getDefaultRect.call(this)
        );
        this.setState({ crop: restoreRect.call(this, rect, scale) });
    } else {
        this.setState({ crop: {} });
    }
};

export const onChangeLinkUrl = function ({ currentTarget: { value } }) {
    const { selectedSlide, slides, arrayNumber } = this.state;

    try {
        this.setState({
            slides: set(
                slides,
                [selectedSlide, 'links', arrayNumber, 'url'],
                value
            ),
        });
    } catch (err) {
        if (typeof this.props.toggleError === 'function') {
            this.props.toggleError(handleError(err));
        }
    }
};

export const onCropChangeLinks = function (crop, percentCrop) {
    const { arrayNumber, selectedSlide, slides } = this.state;

    this.setState({ crop: percentCrop });

    if (slides[selectedSlide]) {
        const scale = getScale.call(this);
        const rect = getRect.call(this, crop, scale);

        if (!has(slides, [selectedSlide, 'links'])) {
            set(slides, ['links', arrayNumber], { url: '', rect });
        } else {
            set(slides, [selectedSlide, 'links', arrayNumber, 'rect'], rect);
        }

        this.setState({ slides });
    }
};

export const changeLinkNumber = function ({ currentTarget: { value } }) {
    const newIndex = parseInt(value, 10) - 1; // Parse once and store the index.
    this.setState({ arrayNumber: newIndex }, () => {
        const { selectedSlide, slides } = this.state;
        const slideLinks = slides[selectedSlide].links || [];
        const existingRect = get(slideLinks, [newIndex, 'rect']);
        const rect = existingRect || getDefaultRect.call(this);

        // Ensure links array has sufficient length.
        while (slideLinks.length <= newIndex) {
            slideLinks.push({ path: '', rect: getDefaultRect.call(this) });
        }

        this.setState({
            slides: set(slides, [selectedSlide, 'links'], slideLinks),
            crop: restoreRect.call(this, rect, getScale.call(this)),
        });
    });
};

export const changeSlide = function ({ currentTarget: { value } }) {
    const slideIndex = parseInt(value, 10) - 1;
    const scale = getScale.call(this);
    this.setState({ selectedSlide: slideIndex, arrayNumber: 0 }, () => {
        const { slides, selectedSlide } = this.state; // Re-read selectedSlide from state in case it was asynchronously updated
        const rect = has(slides, [selectedSlide, 'links'])
            ? get(slides, [selectedSlide, 'links', 0, 'rect'])
            : getDefaultRect.call(this); // Consider having a default rect from a getDefaultRect function or similar
        this.setState({ crop: restoreRect.call(this, rect, scale) });
    });
};

export const toggleLinkModal = function () {
    const { slides, showChooseLinkModal, arrayNumber } = this.state;

    if (!showChooseLinkModal) {
        this.setState({
            previousArrayNumber: arrayNumber,
            showChooseLinkModal: !showChooseLinkModal,
            oldVals: cloneDeep(slides),
        });
    } else {
        this.setState({ showChooseLinkModal: !showChooseLinkModal });
    }
};

export const setSelectedLinks = function (selectedLinks) {
    if (typeof selectedLinks === 'function') {
        const links = selectedLinks(this.state.selectedLinks);
        this.setState({ selectedLinks: links });
    } else {
        this.setState({ selectedLinks });
    }
};

export const copyLinksToSlides = function (
    sourceSlide,
    targetSlideIds,
    slides
) {
    if (!sourceSlide.links || sourceSlide.links.length === 0) {
        console.log('No links found in source slide');
        return slides;
    }
    // Filter out undefined and null values
    const sourceLinks = sourceSlide.links.filter((link) => link);

    // Clone the slides so we don't mutate the originals
    let updatedSlides = [...slides];

    // Map over slides to create a new array
    updatedSlides = updatedSlides.map((slide) => {
        // If this slide is a target, update its links
        if (targetSlideIds.includes(slide.id)) {
            let targetLinks = slide.links
                ? slide.links.filter((link) => link)
                : [];
            // Iterate over each source link and process accordingly
            sourceLinks.forEach((sourceLink) => {
                const existingLinkIndex = targetLinks.findIndex(
                    (targetLink) => targetLink.url === sourceLink.url
                );
                if (existingLinkIndex !== -1) {
                    // Link with the same URL found, transfer the coordinates
                    targetLinks[existingLinkIndex].rect = {
                        ...sourceLink.rect,
                    };
                } else {
                    // No matching URL found, push the new link
                    targetLinks.push({ ...sourceLink });
                }
            });
            // Return the slide with updated links
            return {
                ...slide,
                links: targetLinks,
            };
        }
        // If this slide is not a target, leave it unchanged
        return slide;
    });

    console.log('Updated slides:', updatedSlides);
    return updatedSlides;
};

const onJsonEditorChange = (json) => {
    try {
        const parsedJson = JSON.parse(json);
        this.setState({ slides: parsedJson });
    } catch (err) {
        console.error('Error parsing JSON:', err);
    }
};

const removeDuplicates = function () {
    const { slides } = this.state;

    const updatedSlides = slides.map((slide) => {
        if (!slide.links) {
            return slide;
        }

        const uniqueLinks = [];
        const seenUrls = new Set();

        slide.links.forEach((link) => {
            if (!seenUrls.has(link.url)) {
                seenUrls.add(link.url);
                uniqueLinks.push(link);
            }
        });
        return {
            ...slide,
            links: uniqueLinks,
        };
    });
    this.setState({ slides: updatedSlides });
};

export const initialize = function (self) {
    self.setScale = setScale.bind(self);
    self.getDeckJson = getDeckJson.bind(self);
    self.setCrop = setCrop.bind(self);
    self.preloadImages = preloadImages.bind(self);
    self.changeSlide = changeSlide.bind(self);
    self.changeLinkNumber = changeLinkNumber.bind(self);
    self.clear = clear.bind(self, 'links');
    self.clearAll = clearAll.bind(self, 'links');
    self.onChangeUrl = onChangeLinkUrl.bind(self);
    self.onCropChange = onCropChangeLinks.bind(self);
    self.onImageLoaded = onImageLoaded.bind(self);
    self.submit = submit.bind(self, 'links');
    self.toggleLinkModal = toggleLinkModal.bind(self);
    self.restoreOldVals = restoreOldVals.bind(self);
    self.nextSlide = nextSlide.bind(self);
    self.prevSlide = prevSlide.bind(self);
    self.manuallyUpdateJson = manuallyUpdateJson.bind(self);
    self.downloadJson = downloadJson.bind(self);
    self.setSlideJson = setSlideJson.bind(self);
    self.setBackupSlide = setBackupSlide.bind(self);
    self.copyLinksToSlides = copyLinksToSlides.bind(self);
    self.onJsonEditorChange = onJsonEditorChange.bind(self);
    self.removeDuplicates = removeDuplicates.bind(self);
    self.uploadJson = uploadJson.bind(self);
    self.addLink = addLink.bind(self);
};
