import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';

import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Typeahead } from 'react-bootstrap-typeahead';
import Editor from 'react-simple-code-editor';
import Modal from 'react-bootstrap/Modal';
import { ButtonGroup } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import has from 'lodash/has';

import LinkModal from './LinkModal';
import HelpModal from '../help/linkEditorHelp';
import ResultModal from '../shared/ResultModal';
import Carousel from './Carousel';
import EditorButtonGroup from './EditorButtonGroup';
import EditorSlideSelector from './EditorSlideSelector';
import CopyLinksComponent from './CopyLinksSelector';

import { initialize, setSelectedLinks } from './helpers/links';
import { getVaultId } from './helpers';

import 'prismjs/components/prism-json.min';
import 'prismjs/themes/prism.css';
import 'prismjs/themes/prism-okaidia.css';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const styles = StyleSheet.create({
    pre: {
        backgroundColor: '#e9ecef',
        width: '100%',
    },
    json: {
        paddingTop: '10px',
    },
});

class DeckLinkEditor extends React.Component {
    state = {
        crop: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
            unit: '%',
        },
        scale: {
            x: 0,
            y: 0,
        },
        done: false,
        error: false,
        deckIsLoaded: false,
        deckIsInvalid: true,
        deck: {
            vaultId: '',
        },
        isDam: false,
        images: [],
        slides: [],
        showJson: false,
        showHelpModal: false,
        showChooseLinkModal: false,
        showResultModal: false,
        oldVals: null,
        damJson: {},
        selectedSlide: 0,
        showHelp: false,
        arrayNumber: 0,
        previousArrayNumber: 0,
        property: 'links',
        selectedLinks: [],
        showWarning: false,
        isLoading: false,
    };

    constructor(props) {
        super(props);
        this.image = null;
        this.initialize = initialize.bind(this);
        this.initialize(this);
        this.inputRef = React.createRef();
    }

    changeDeck = async ([deck]) => {
        if (!deck) {
            return;
        }

        this.setState({
            deck,
            deckIsLoaded: false,
            deckIsInvalid: false,
            isLoading: true,
        });

        const { setParams, params } = this.props;

        const vaultId = getVaultId(deck);
        setParams({ deck: vaultId });

        await this.getDeckJson({ vaultId });

        this.setState({
            deckIsLoaded: true,
            isLoading: false,
            deckIsInvalid: false,
            deck,
            isDam: vaultId?.includes('DAM'),
        });
    };

    componentDidMount() {
        window.addEventListener('resize', this.setScale);
        window.addEventListener('keydown', this.handleKeyDown);

        // handle incoming url params
        const { params } = this.props;

        console.log('params: ', params);

        if (!isEmpty(params.deck)) {
            this.setState({ deck: { vaultId: params.deck }, isLoading: true });
            this.getDeckJson({ vaultId: params.deck }).then(() => {
                this.setState({ isLoading: false });
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setScale);
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.state.slides !== prevState.slides &&
            typeof this.state.slides !== 'string'
        ) {
            this.setState({
                editableJson: JSON.stringify(this.state.slides, null, 3),
            });
        }
    }

    handleKeyDown = (event) => {
        if (event.key === 'J' && event.shiftKey) {
            this.setState((prevState) => ({
                showJson: !prevState.showJson,
            }));
        }
    };

    render() {
        const { decks, params } = this.props;

        const {
            crop,
            done,
            deck,
            deckIsLoaded,
            deckIsInvalid,
            error,
            images,
            isLoading,
            arrayNumber,
            selectedSlide,
            selectedLinks,
            slides,
            showJson,
            showHelp,
            showChooseLinkModal,
            showResultModal,
            editableJson,
        } = this.state;

        const slide = get(slides, [selectedSlide], {});
        const hasUrls = has(slides, [selectedSlide, 'links']);

        const hasRect = has(slides, [
            selectedSlide,
            'links',
            arrayNumber,
            'rect',
        ]);

        return (
            <>
                <Container>
                    <Form>
                        <Form.Row>
                            <h1>Link Editor</h1>
                            <p
                                onClick={() =>
                                    this.setState({
                                        showHelpModal: !this.state.showHelp,
                                    })
                                }>
                                ⓘ
                            </p>
                        </Form.Row>
                        <Form.Row>
                            <Carousel
                                arrayNumber={arrayNumber}
                                crop={crop}
                                scale={this.state.scale}
                                deck={deck.vaultId}
                                deckIsLoaded={deckIsLoaded}
                                deckIsInvalid={deckIsInvalid}
                                isLoading={isLoading}
                                hasObject={hasUrls}
                                images={images}
                                slides={slides}
                                setSlides={(slides) =>
                                    this.setState({ slides })
                                }
                                onLoadedVideoData={this.onLoadedVideoData}
                                removeLink={this.clear}
                                selectedLinks={selectedLinks}
                                setSelectedLinks={setSelectedLinks.bind(this)}
                                selectedSlide={selectedSlide}
                                changeSelectedObject={this.changeLinkNumber}
                                onCropChange={this.onCropChange}
                                onImageLoaded={this.onImageLoaded}
                                nextSlide={this.nextSlide}
                                prevSlide={this.prevSlide}
                                property={this.state.property}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId='deck'>
                                <Form.Label>Deck</Form.Label>
                                <Typeahead
                                    id='deck-typeahead'
                                    options={decks || [params.deck]}
                                    selected={[deck.vaultId]}
                                    placeholder='Enter deck vault ID'
                                    onChange={this.changeDeck}
                                    labelKey='vaultId'
                                    isInvalid={deckIsInvalid}
                                    isValid={deckIsLoaded && !deckIsInvalid}
                                    isLoading={isLoading}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    Deck is invalid
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type='valid'>
                                    Deck is valid
                                </Form.Control.Feedback>
                            </Form.Group>

                            <EditorSlideSelector
                                deckIsLoaded={deckIsLoaded}
                                property={this.state.property}
                                changeSlide={this.changeSlide}
                                slides={slides}
                                selectedSlide={selectedSlide}
                            />

                            <Form.Group as={Col} xs={2}>
                                <DropdownButton
                                    style={{
                                        marginTop: '32px',
                                        height: '38px',
                                        width: '100%',
                                    }}
                                    as={ButtonGroup}
                                    title='Actions'
                                    className='btn-block'
                                    disabled={!deckIsLoaded}>
                                    <Dropdown.Item
                                        disabled={!deckIsLoaded}
                                        eventKey='1'
                                        onClick={() =>
                                            this.setState({
                                                showWarning: true,
                                            })
                                        }>
                                        Remove Duplicates
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() =>
                                            this.setState({
                                                showJson: !showJson,
                                            })
                                        }
                                        disabled={!deckIsLoaded}
                                        eventKey='2'>
                                        Show JSON
                                    </Dropdown.Item>
                                    <input
                                        type='file'
                                        id='file'
                                        style={{ display: 'none' }}
                                        onChange={this.uploadJson}
                                        ref={this.inputRef}
                                    />
                                    <Dropdown.Item
                                        onClick={() =>
                                            this.inputRef.current?.click()
                                        }
                                        disabled={!deckIsLoaded}
                                        eventKey='3'>
                                        Upload JSON
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={this.downloadJson}
                                        disabled={!deckIsLoaded}
                                        eventKey='4'>
                                        Download JSON
                                    </Dropdown.Item>
                                </DropdownButton>
                            </Form.Group>
                        </Form.Row>

                        <CopyLinksComponent
                            slides={slides}
                            sourceSlideId={slide.id}
                            copyLinksToSlides={this.copyLinksToSlides}
                            setSlides={(slides) => this.setState({ slides })}
                            isDeckLoaded={deckIsLoaded}
                        />

                        <Form.Row>
                            <Col>
                                <EditorButtonGroup
                                    addObject={this.addLink}
                                    arrayNumber={arrayNumber}
                                    property={this.state.property}
                                    togglePropertyModal={this.toggleLinkModal}
                                    clear={this.clear}
                                    clearAll={this.clearAll}
                                    deckIsLoaded={deckIsLoaded}
                                    deckIsInvalid={deckIsInvalid}
                                    hasRect={hasRect}
                                />
                                <Button
                                    block
                                    onClick={() => this.submit()}
                                    disabled={!deckIsLoaded || deckIsInvalid}
                                    eventKey='3'>
                                    Save
                                    {isLoading && (
                                        <Spinner
                                            as='span'
                                            animation='grow'
                                            role='status'
                                            aria-hidden='true'
                                            size='sm'
                                        />
                                    )}
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>

                    <Collapse in={showJson}>
                        <Row className={css(styles.json)}>
                            <Col>
                                <Editor
                                    value={editableJson}
                                    onValueChange={(code) => {
                                        this.setState({ editableJson: code });
                                    }}
                                    highlight={(code) =>
                                        highlight(
                                            code || '[]',
                                            languages.json,
                                            'language-json'
                                        )
                                    }
                                    style={{
                                        fontFamily:
                                            '"Fira code", "Fira Mono", monospace',
                                        fontSize: 18,
                                        backgroundColor: '#282c34',
                                        color: '#212529',
                                        borderRadius: '4px',
                                        boxShadow:
                                            '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        overflowY: 'scroll',
                                        height: '850px',
                                        marginBottom: '20px',
                                    }}
                                />
                            </Col>
                        </Row>
                    </Collapse>
                </Container>

                <HelpModal show={showHelp} handleClose={this.toggleHelpModal} />

                <LinkModal
                    arrayNumber={arrayNumber}
                    hasUrls={hasUrls}
                    show={showChooseLinkModal}
                    deckIsLoaded={deckIsLoaded}
                    slides={slides}
                    selectedSlide={selectedSlide}
                    onChangeUrl={this.onChangeUrl}
                    restoreOldVals={this.restoreOldVals}
                    handleClose={this.toggleLinkModal}
                />

                <ResultModal
                    show={showResultModal}
                    vaultId={deck.vaultId}
                    error={error}
                    successMsg={`${deck.vaultId} was successfully updated`}
                    errMsg={
                        error || `There was an error updating ${deck.vaultId}`
                    }
                    handleClose={() =>
                        this.setState({
                            showResultModal: !this.state.showResultModal,
                        })
                    }
                />

                <Modal show={this.state.showWarning} centered>
                    <Modal.Header>
                        <Modal.Title>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            You are about to remove duplicate links from all
                            slides. This can be a destructive action. Are you
                            sure you want to proceed?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            onClick={() =>
                                this.setState({ showWarning: false })
                            }>
                            Cancel
                        </Button>
                        <Button
                            variant='danger'
                            onClick={() => {
                                this.setState({ showWarning: false });
                                this.removeDuplicates();
                            }}>
                            Remove
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

DeckLinkEditor.propTypes = {
    params: PropTypes.object.isRequired,
    setParams: PropTypes.func.isRequired,
    decks: PropTypes.arrayOf(PropTypes.string),
};

DeckLinkEditor.defaultProps = {
    decks: [],
};

export default DeckLinkEditor;
