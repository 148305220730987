import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import has from 'lodash/has';

const EditorSlideSelector = ({
    changeSlide,
    property,
    deckIsLoaded,
    slides,
    selectedSlide,
}) => (
    <Form.Group as={Col} xs={2}>
        <Form.Label>Slide</Form.Label>
        <Form.Control
            as='select'
            onChange={changeSlide}
            disabled={!deckIsLoaded || !slides || slides.length === 0}
            value={(selectedSlide + 1).toString()}
            custom>
            {slides ? (
                slides.map((slide, idx) => (
                    <option key={idx} value={idx + 1}>
                        {idx + 1}{' '}
                        {has(slide, [property]) ? `(${property})` : ''}
                    </option>
                ))
            ) : (
                <option>N/A</option>
            )}
        </Form.Control>
    </Form.Group>
);

EditorSlideSelector.propTypes = {
    changeSlide: PropTypes.func.isRequired,
    property: PropTypes.string.isRequired,
    deckIsLoaded: PropTypes.bool.isRequired,
    slides: PropTypes.array,
    selectedSlide: PropTypes.number.isRequired,
};

export default EditorSlideSelector;
