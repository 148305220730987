import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import { useUrlSearchParams } from 'use-url-search-params';
import isEmpty from 'lodash/isEmpty';

import Menu from '../components/Menu';
import Progress from '../components/Progress';
import PrivateRoute from '../components/PrivateRoute';

import Categories from './Categories';
import ShowParams from './ShowParams';
import Home from './Home';
import Login from './Login';
import Remover from '../components/remover';
import Uploader from './Uploader';
import Workers from './Workers';
import Editor from './Editor';
import Links from '../components/uploader/LinkThumbnailUploader';
import Tokenizer from './Tokenizer';
import VaultRemover from '../components/vault/VaultRemover';
import VaultUploader from '../components/vault/Uploader';
import VaultTest from './VaultTest';
import VaultLinks from '../components/vault/VaultLinkThumbnailEditor';
import QueryTest from './QueryTest';
import RouteTest from './RouteTest';
import Logs from './Logs';

import {
    fetchLatestCommitSha,
    fetchLogs,
    login,
    validateSession,
} from '../redux/actions';

const Main = () => {
    const params = useUrlSearchParams();
    const [error, setError] = useState(false);
    const dispatch = useDispatchRequest();
    const location = useLocation();
    const {
        username,
        password,
        loggedInAs,
        passwordIsGood,
        data: sessionId,
    } = useSelector(state => state.login);
    const commitSha = useQuery({
        type: 'FETCH_LATEST_COMMIT_SHA',
        action: fetchLatestCommitSha,
        autoLoad: true,
    });
    const { data: logs } = useQuery({
        type: 'FETCH_LOGS',
        action: fetchLogs,
        autoLoad: true,
    });

    useEffect(() => {
        const validate = async () => {
            const { data: session } = await dispatch(
                validateSession(sessionId)
            );

            if (session === 'invalid') {
                if (
                    !isEmpty(username) &&
                    !isEmpty(password) &&
                    passwordIsGood
                ) {
                    dispatch(login({ username, password }));
                }
                setError('Invalid Session ID');
            }
        };
        // we only want to run this validation if we're in standalone mode
        if (!params.sessionId) {
            validate();
        }
    }, []);

    return (
        <>
            <Progress />
            <Menu
                location={location}
                sessionId={sessionId}
                commitSha={commitSha}
                username={loggedInAs}
            />

            <Switch>
                <Route path='/login' component={Login} />
                <PrivateRoute path='/' exact component={Home} />
                <Route path='/vault/uploader' component={VaultUploader} />
                <Route path='/vault/remover' component={VaultRemover} />
                <Route path='/vault/links' component={VaultLinks} />
                <PrivateRoute
                    sessionId={sessionId}
                    path='/params'
                    component={ShowParams}
                />

                <PrivateRoute
                    sessionId={sessionId}
                    path='/uploader'
                    component={Uploader}
                />

                <PrivateRoute
                    sessionId={sessionId}
                    path='/remover'
                    component={Remover}
                />

                <PrivateRoute
                    sessionId={sessionId}
                    path='/video'
                    component={Editor}
                />
                <PrivateRoute
                    sessionId={sessionId}
                    path='/links'
                    component={Editor}
                />
                <PrivateRoute
                    sessionId={sessionId}
                    path='/thumbnail'
                    component={Links}
                />

                <PrivateRoute
                    sessionId={sessionId}
                    path='/workers'
                    component={Workers}
                />

                <PrivateRoute
                    sessionId={sessionId}
                    path='/vaultTest'
                    component={VaultTest}
                />

                <PrivateRoute
                    sessionId={sessionId}
                    path='/queryTest'
                    component={QueryTest}
                />

                <PrivateRoute
                    sessionId={sessionId}
                    path='/routeTest'
                    component={RouteTest}
                />

                <PrivateRoute
                    sessionId={sessionId}
                    path='/tokenizer'
                    component={Tokenizer}
                />

                <PrivateRoute
                    sessionId={sessionId}
                    path='/logs'
                    component={Logs}
                    logs={logs}
                />

                <PrivateRoute
                    sessionId={sessionId}
                    path='/categories'
                    component={Categories}
                />
            </Switch>
        </>
    );
};

export default Main;
