import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import noop from 'lodash/noop';

import { updateProgress } from './redux/actions';

import Main from './containers/Main';
import { configureStore } from './redux/store/configureStore';

import '../public/index.scss';

const { store, persistor } = configureStore();

// NOTE: replace this with the new meta property in actions:
// https://redux-requests.klisiczynski.com/docs/drivers/axios-driver#progress-event-support
axios.interceptors.request.use(config => {
    return {
        ...config,
        onDownloadProgress:
            config.responseType === 'blob' ||
            config.responseType === 'arraybuffer'
                ? update
                : noop,
        onUploadProgress: update,
    };
});

const update = event => {
    store.dispatch(
        updateProgress({ loaded: event.loaded, total: event.total })
    );
};

const App = () => (
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Main />
            </PersistGate>
        </Provider>
    </BrowserRouter>
);

const root = document.getElementById('app');

if (root) {
    ReactDOM.render(<App />, root);
}
