import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { isVideo } from '../helpers';
import React from 'react';
import PropTypes from 'prop-types';

const FormOperationSelector = ({
    mode,
    deckExists,
    documentType,
    setOperation,
}) => {
    return (
        <Form.Group as={Col} controlId='mode'>
            <Form.Label>Operation</Form.Label>
            <ToggleButtonGroup
                name='mode'
                vertical={false}
                style={{ width: '100%' }}
                type='radio'
                value={mode}>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip id='button-tooltip-1'>
                            Uploads new slides, pdf, or video and updates ACE
                            data
                        </Tooltip>
                    }>
                    {({ ref, ...triggerHandler }) => (
                        <ToggleButton
                            {...triggerHandler}
                            ref={ref}
                            autoComplete='off'
                            type='radio'
                            name='mode'
                            variant='outline-secondary'
                            value='deploy'
                            checked={mode === 'deploy'}
                            active={mode === 'deploy'}
                            onClick={() => setOperation('deploy')}>
                            {deckExists
                                ? 'Update All'
                                : isVideo(documentType)
                                ? 'Create Video'
                                : 'Create Deck'}
                        </ToggleButton>
                    )}
                </OverlayTrigger>
                {deckExists ? (
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='button-tooltip-2'>
                                Only updates ACE data, does not require
                                uploading slides or corresponding files,
                            </Tooltip>
                        }>
                        {({ ref, ...triggerHandler }) => (
                            <ToggleButton
                                {...triggerHandler}
                                ref={ref}
                                name='mode'
                                type='radio'
                                autoComplete='off'
                                checked={mode === 'update'}
                                variant='outline-secondary'
                                value='update'
                                active={mode === 'update'}
                                disabled={!deckExists}
                                onClick={() => setOperation('update')}>
                                Update ACE Data
                            </ToggleButton>
                        )}
                    </OverlayTrigger>
                ) : (
                    <ToggleButton
                        name='mode'
                        type='radio'
                        checked={mode === 'update'}
                        variant='outline-secondary'
                        value='update'
                        active={mode === 'update'}
                        disabled={true}>
                        Update ACE Data
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
        </Form.Group>
    );
};

FormOperationSelector.propTypes = {
    mode: PropTypes.string.isRequired,
    deckExists: PropTypes.bool.isRequired,
    documentType: PropTypes.string.isRequired,
    setOperation: PropTypes.func.isRequired,
};

FormOperationSelector.defaultProps = {
    mode: 'deploy',
    deckExists: false,
    documentType: 'pdf',
};

export default FormOperationSelector;
