import axios from 'axios';
import capitalize from 'lodash/capitalize';
import first from 'lodash/first';
import has from 'lodash/has';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import downloadFile from 'js-file-download';

import { handleError } from '../../uploader/helpers';

export const defaultRect = {
    x: 433,
    y: 270,
    width: 865,
    height: 540,
};

export const getVaultId = (opt) => get(opt, 'vaultId') || get(opt, 'v') || opt;

const fetchJson = async function (vaultId, vaultIntegrated, update, job) {
    if (this.props.fetchStagedIndexJson && job && !update) {
        return await this.props.fetchStagedIndexJson(job);
    } else if (this.props.fetchStagedIndexJson && job && update) {
        const { data: staged } = await this.props.fetchStagedIndexJson(job);
        const { data } = await this.props.fetchDeck(vaultId);

        let slides = get(data, 'slides', data);
        const stagedSlides = get(staged, 'slides', staged);

        return {
            data: slides
                .map(
                    (slide) =>
                        (slide = find(stagedSlides, { id: slide.id }) || slide)
                )
                .slice(0, stagedSlides.length),
        };
    } else {
        // pull from sql like normal
        return await this.props.fetchDeck(vaultId);
    }
};

export const getDeckJson = async function ({
    job,
    vaultId,
    vaultIntegrated = false,
    update = false,
}) {
    const { isDam } = this.state;

    try {
        const { data: entry } = await fetchJson.call(
            this,
            vaultId,
            vaultIntegrated,
            update,
            job
        );

        let slides = get(entry, 'slides', entry);
        await this.preloadImages(vaultId, slides);

        const jsonString = JSON.stringify(isDam ? entry : slides, null, 3);

        this.setState(
            {
                slides,
                arrayNumber: 0,
                selectedSlide: 0,
                jsonString,
                deckIsInvalid: false,
                deckIsLoaded: true,
                error: false,
            },
            this.setCrop ? this.setCrop : noop
        );

        if (isEmpty(slides)) {
            throw new Error(
                `Received 0 slides from API, deck is invalid. Try reuploading ${vaultId}.`
            );
        }
    } catch (err) {
        const handledError = handleError(err);
        if (isFunction(this.props.toggleError)) {
            this.props.toggleError(handledError);
        }

        this.setState({
            deckIsInvalid: true,
            showResultModal: true,
            error: handledError,
        });
    }
};

const getOriginalRect = function (state) {
    return get(state.slides, [
        state.selectedSlide,
        state.property,
        state.arrayNumber,
        'rect',
    ]);
};

export const setScale = function () {
    const image = this.image || document.querySelector('.ReactCrop__image');
    const x = image.naturalWidth / image.width;
    const y = image.naturalHeight / image.height;

    if (getOriginalRect(this.state)) {
        const rect = getOriginalRect(this.state);
        const scale = { x, y };
        this.setState({ crop: restoreRect.call(this, rect, scale) });
    } else {
        this.setState({ crop: {} });
    }

    this.setState({
        scale: { x, y },
        image: {
            height: image.height,
            width: image.width,
        },
    });
};

export const onImageLoaded = function (image) {
    this.image = image;

    this.setScale();

    // remove old listener
    if (typeof window.onresize === 'function') {
        window.removeEventListener('resize', this.setScale);
    }

    window.addEventListener('resize', this.setScale);

    return false;
};

export function validateSlides(property, slides) {
    const validateProperty = (item, propName) => {
        if (!item[propName]) {
            throw new Error(`${capitalize(property)} ${propName} is missing.`);
        }
        if (isEmpty(item[propName])) {
            throw new Error(`${capitalize(property)} ${propName} is empty.`);
        }
    };

    slides.forEach((slide) => {
        const items = slide[property];
        if (items) {
            (Array.isArray(items) ? items : [items]).forEach((item) => {
                const propName = property === 'video' ? 'path' : 'url';
                validateProperty(item, propName);
            });
        }
    });
    return true;
}

export const submit = async function (noValidate = false) {
    try {
        console.log('[NO VALIDATE?]', noValidate);
        const { isDam, deck, slides } = this.state;

        // Validate the slides if necessary
        if (!noValidate) {
            validateSlides(this.state.property, slides);
        }

        this.setState({ isLoading: true });

        // Prepare submission data
        const vaultId = Array.isArray(deck)
            ? getVaultId(first(deck))
            : getVaultId(deck);
        const apiEndpoint = isDam ? '/api/update/dam' : '/api/update/deck';

        // Submit the data
        await axios.post(apiEndpoint, {
            vaultId,
            slides,
        });

        // Update state after successful submission
        this.setState({
            error: false,
            done: true,
            isLoading: false,
        });

        if (!noValidate && isFunction(this.toggleResultModal)) {
            this.toggleResultModal();
        }
    } catch (err) {
        console.error('Error in submitVideo:', err);

        // Handle errors
        this.setState({
            error: handleError(err),
            isLoading: false,
        });

        if (!noValidate) {
            if (isFunction(this.props.toggleError)) {
                this.props.toggleError(handleError(err));
            }
            if (isFunction(this.toggleResultModal)) {
                this.toggleResultModal();
            }
        }
    }
};

export function preloadImages(deck, slides) {
    return new Promise((resolve) => {
        const images = new Array(slides.length);
        let loadedCount = 0;

        const updateImageState = () => {
            this.setState({ images }, () => {
                if (loadedCount === slides.length) {
                    resolve(images);
                }
            });
        };

        slides.forEach((slide, idx) => {
            const img = new Image();
            img.onload = () => {
                images[idx] = img.src; // Store the actual image URL
                loadedCount++;
                updateImageState(); // Update state and resolve if it's the last image
            };
            img.onerror = () => {
                // Handle loading error: you might want to use a placeholder or retry logic
                console.error(`Error loading image with id: ${slide.id}`);
                loadedCount++;
                updateImageState(); // Update state and resolve if it's the last image
            };
            img.src = `/api/deck/${deck}/${slide.id}`;
        });
    });
}

export const clearAll = function (property) {
    const { slides, selectedSlide } = this.state;
    delete slides[selectedSlide][property];
    this.setState({ slides, crop: {} });
};

export const clear = function (property) {
    const { arrayNumber, slides, selectedSlide } = this.state;

    if (has(slides, [selectedSlide, property])) {
        slides[selectedSlide][property].splice(arrayNumber, 1);
        if (slides[selectedSlide][property].length === 0) {
            delete slides[selectedSlide][property];
        }

        const newArrayNumber = arrayNumber === 0 ? 0 : arrayNumber - 1;

        // check if there's a previous rect to restore
        // set the crop to the previous rect
        // if there's no previous rect, then clear the crop
        const crop = getOriginalRect(this.state)
            ? restoreRect.call(this, getOriginalRect(this.state))
            : {};

        this.setState({ slides, arrayNumber: newArrayNumber, crop });
    }
};

export const nextObject = function (property) {
    const { arrayNumber } = this.state;
    const nextSlide = arrayNumber + 1;
    const event = {
        currentTarget: { value: (nextSlide + 1).toString() },
    };

    if (property === 'video') {
        this.changeVideoNumber(event);
    } else {
        this.changeLinkNumber(event);
    }
};

export const prevObject = function (property) {
    const { arrayNumber } = this.state;
    const prevSlide = arrayNumber - 1 < 0 ? 0 : arrayNumber - 1;
    const event = {
        currentTarget: { value: (prevSlide + 1).toString() },
    };

    if (property === 'video') {
        this.changeVideoNumber(event);
    } else {
        this.changeLinkNumber(event);
    }
};

export const manuallyUpdateJson = function (code) {
    console.log('manually updating json:', code);
    this.setState({ editableJson: code });
};

export const getScale = function () {
    const image = this.image;
    const x = image.naturalWidth / image.width;
    const y = image.naturalHeight / image.height;
    return { x, y };
};

// lol
export const getRect = function (crop, scale = getScale.call(this)) {
    return {
        x: Math.round(crop.x * scale.x),
        y: Math.round(crop.y * scale.y),
        width: Math.round(crop.width * scale.x),
        height: Math.round(crop.height * scale.y),
    };
};

export const getDefaultRect = function () {
    // get the default rect coordinates
    // representing the center of the image

    const image = this.image;
    const { width: imageWidth, height: imageHeight } =
        image.getBoundingClientRect();

    return {
        x: imageWidth / 4,
        y: imageHeight / 4,
        width: imageWidth / 2,
        height: imageHeight / 2,
    };
};

export const restoreRect = function (
    rect = getDefaultRect.call(this),
    scale = getScale.call(this)
) {
    return {
        x: Math.round(rect.x / scale.x),
        y: Math.round(rect.y / scale.y),
        width: Math.round(rect.width / scale.x),
        height: Math.round(rect.height / scale.y),
    };
};

export const downloadJson = function () {
    const { damJson, slides, isDam } = this.state;
    const json = JSON.stringify(isDam ? damJson : slides, null, 3);
    downloadFile(json, 'index.json');
};

export const setSlideJson = function ({ currentTarget: { value } }) {
    const { slides, selectedSlide, selectedBackupSlide, backupJson } =
        this.state;

    this.setState({ backup: value });

    set(
        slides,
        [selectedSlide],
        cloneDeep(backupJson[value].slides[selectedBackupSlide])
    );

    this.setState({ slides }, this.setCrop);
};

export const setBackupSlide = function ({ currentTarget: { value } }) {
    this.setState({ selectedBackupSlide: value }, () => {
        const {
            backup,
            slides,
            selectedSlide,
            selectedBackupSlide,
            backupJson,
        } = this.state;
        const index = parseInt(selectedBackupSlide);

        set(
            slides,
            [selectedSlide],
            cloneDeep(backupJson[backup].slides[index])
        );

        this.setState({ slides }, this.setCrop);
    });
};
export const restoreOldVals = function () {
    const { oldVals, previousArrayNumber } = this.state;

    // set the crop to the previous rect, if it exists
    // if it doesn't exist, then clear the crop
    const crop = get(
        oldVals,
        [
            this.state.selectedSlide,
            this.state.property,
            previousArrayNumber,
            'rect',
        ],
        {}
    );

    this.setState({
        arrayNumber: previousArrayNumber,
        slides: oldVals,
        crop,
    });
};

export const uploadJson = async function (e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
        const text = e.target.result;
        const json = JSON.parse(text);
        this.setState(
            { slides: json, editableJson: JSON.stringify(json) },
            this.setCrop
        );
    };
    reader.onerror = (e) => {
        console.error('Error reading file:', e);
    };
    reader.readAsText(file);
};

export const addLink = function () {
    const {
        slides,
        selectedSlide,
        arrayNumber: previousArrayNumber,
    } = this.state;
    const oldVals = cloneDeep(slides);

    if (!slides[selectedSlide].links) {
        slides[selectedSlide].links = [];
    }

    // set the array number to the last index
    const arrayNumber = slides[selectedSlide].links.length;
    slides[selectedSlide].links.push({
        url: '',
        rect: getDefaultRect.call(this),
    });

    this.setState({
        oldVals,
        slides,
        arrayNumber,
        previousArrayNumber,
        crop: restoreRect.call(this, getDefaultRect.call(this)),
    });
};

export const nextSlide = function () {
    const { slides, selectedSlide } = this.state;
    if (selectedSlide + 1 < slides.length) {
        this.setState(
            { selectedSlide: selectedSlide + 1 },
            isFunction(this.setCrop) ? this.setCrop : noop
        );
    }
};

export const prevSlide = function () {
    const { selectedSlide } = this.state;
    if (!(selectedSlide - 1 < 0)) {
        this.setState(
            { selectedSlide: selectedSlide - 1 },
            isFunction(this.setCrop) ? this.setCrop : noop
        );
    }
};
