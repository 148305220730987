import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import has from 'lodash/has';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import LinkModal from '../../editor/LinkModal';
import Carousel from '../../editor/Carousel';
import EditorButtonGroup from '../../editor/EditorButtonGroup';
import EditorSlideSelector from '../../editor/EditorSlideSelector';
import CopyLinksComponent from '../../editor/CopyLinksSelector';

import { initialize } from '../../editor/helpers/links';

class VaultDeckLinkEditor extends React.Component {
    state = {
        crop: {
            x: 0,
            y: 0,
            height: 0,
            width: 0,
            unit: '%',
        },
        scale: {
            x: 0,
            y: 0,
        },
        done: false,
        error: false,
        loading: false,
        deckIsLoaded: false,
        deckIsInvalid: true,
        deck: this.props.vaultId,
        isDam: false,
        images: [],
        slides: this.props.slides || [],
        showJson: false,
        showHelpModal: false,
        showChooseLinkModal: false,
        showResultModal: false,
        oldVals: null,
        damJson: {},
        selectedSlide: 0,
        showHelp: false,
        arrayNumber: 0,
        property: 'links',
        selectedLinks: [],
        showWarning: false,
        isLoading: false,
        backup: 'sqlIndexJson',
    };

    constructor(props) {
        super(props);
        this.image = null;
        this.initialize = initialize.bind(this);
        this.initialize(this);
    }

    componentDidUpdate(prevProps) {
        const { open, slides } = this.props;

        if (slides !== prevProps.slides) {
            this.setState({ slides });
        } else if (open !== prevProps.open && !open) {
            window.removeEventListener('resize', this.setScale);
        } else if (open !== prevProps.open && open) {
            window.addEventListener('resize', this.setScale);
            setTimeout(this.setScale, 100);
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.setScale);
        window.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setScale);
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    render() {
        const {
            crop,
            deck,
            arrayNumber,
            selectedSlide,
            slides,
            showChooseLinkModal,
        } = this.state;

        const { images, deckIsLoaded, deckIsInvalid } = this.props;

        const slide = slides[selectedSlide] || {};
        const hasUrls = !!slide.links;

        const noRect =
            !has(slides, [selectedSlide, 'links', arrayNumber, 'url']) &&
            !has(slides, [selectedSlide, 'links', arrayNumber, 'rect']);

        return (
            <>
                <Form className='px-2 py-2'>
                    <Form.Row className='d-flex'>
                        <Carousel
                            arrayNumber={arrayNumber}
                            crop={crop}
                            deck={deck}
                            deckIsLoaded={deckIsLoaded}
                            deckIsInvalid={deckIsInvalid}
                            isLoading={this.state.loading}
                            hasObject={hasUrls}
                            images={images}
                            slide={slide}
                            slides={slides}
                            selectedSlide={selectedSlide}
                            changeSelectedObject={this.changeLinkNumber}
                            onCropChange={this.onCropChange}
                            onImageLoaded={this.onImageLoaded}
                            nextSlide={this.nextSlide}
                            prevSlide={this.prevSlide}
                            property={this.state.property}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Links</Form.Label>
                            <Form.Control
                                type='text'
                                readOnly
                                placeholder={get(
                                    slides,
                                    [
                                        selectedSlide,
                                        'links',
                                        arrayNumber,
                                        'url',
                                    ],
                                    ''
                                )}
                            />
                        </Form.Group>
                        <EditorSlideSelector
                            deckIsLoaded={deckIsLoaded}
                            deckIsInvalid={deckIsInvalid}
                            property={this.state.property}
                            changeSlide={this.changeSlide}
                            slides={slides}
                            selectedSlide={selectedSlide}
                        />
                    </Form.Row>
                    <CopyLinksComponent
                        isDeckLoaded={deckIsLoaded}
                        slides={slides}
                        sourceSlideId={slide.id}
                        copyLinksToSlides={this.copyLinksToSlides}
                        setSlides={slides => this.setState({ slides })}
                    />

                    <EditorButtonGroup
                        arrayNumber={arrayNumber}
                        addObject={this.addLink}
                        property={this.state.property}
                        togglePropertyModal={this.toggleLinkModal}
                        clear={this.clear}
                        clearAll={this.clearAll}
                        deckIsLoaded={deckIsLoaded}
                        hasRect={!noRect}
                        deckIsInvalid={deckIsInvalid}
                    />
                </Form>
                <hr />
                <LinkModal
                    arrayNumber={arrayNumber}
                    hasUrls={hasUrls}
                    show={showChooseLinkModal}
                    deckIsLoaded={deckIsLoaded}
                    slides={slides}
                    selectedSlide={selectedSlide}
                    setOldVals={vals => this.setState({ oldVals: vals })}
                    restoreOldVals={this.restoreOldVals}
                    onChangeUrl={this.onChangeUrl}
                    handleClose={this.toggleLinkModal}
                />
            </>
        );
    }
}

VaultDeckLinkEditor.propTypes = {
    deckIsLoaded: PropTypes.bool,
    deckIsInvalid: PropTypes.bool,
    images: PropTypes.array,
    open: PropTypes.bool,
    slides: PropTypes.array,
    useExperimentalImageMatcher: PropTypes.bool,
    vaultId: PropTypes.string,
};

VaultDeckLinkEditor.defaultProps = {
    deckIsLoaded: false,
    deckIsInvalid: false,
    images: [],
    open: false,
    slides: [],
    useExperimentalImageMatcher: false,
    vaultId: '',
};

export default VaultDeckLinkEditor;
