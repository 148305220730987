const POWERPOINT =
'application/vnd.openxmlformats-officedocument.presentationml.presentation';
const DOCX =
'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const DOC = 'application/msword';
const PDF = 'application/pdf';
const VIDEO = 'video/mp4';
const AUDIO = 'audio/mp3';
const ZIP = 'application/x-zip-compressed';

export { POWERPOINT, DOCX, DOC, PDF, VIDEO, AUDIO, ZIP };