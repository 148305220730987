import axios from 'axios';
import clone from 'lodash/cloneDeep';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import replace from 'lodash/replace';
import set from 'lodash/set';
import isFunction from 'lodash/isFunction';
import path from 'path';

import { handleError } from '../../uploader/helpers';

import {
    clear,
    clearAll,
    downloadJson,
    getDeckJson,
    getDefaultRect,
    getRect,
    getScale,
    manuallyUpdateJson,
    nextObject,
    nextSlide,
    onImageLoaded,
    preloadImages,
    prevObject,
    prevSlide,
    restoreOldVals,
    restoreRect,
    setBackupSlide,
    setScale,
    setSlideJson,
    submit,
} from './index';

export const state = {
    aspect: 16 / 9,
    backup: 'sqlIndexJson',
    backupJson: {
        expIndexJson: {},
        sqlIndexJson: {},
    },
    crop: {
        x: 0,
        y: 0,
        height: 0,
        width: 0,
        unit: '%',
    },
    scale: {
        x: 0,
        y: 0,
    },
    error: false,
    loading: false,
    deckIsLoaded: false,
    deckIsInvalid: true,
    deck: '',
    done: false,
    file: null,
    isDam: false,
    images: [],
    showHelpModal: false,
    showUploadModal: false,
    showOverwriteModal: false,
    showChooseVideoModal: false,
    showResultModal: false,
    selectedBackupSlide: 0,
    slides: [],
    showJson: false,
    damJson: {},
    selectedSlide: 0,
    arrayNumber: 0,
    oldVals: {},
    upload: 1,
    worker: {
        progress: 0,
    },
    property: 'video',
};

export const setCrop = function () {
    const { slides, selectedSlide, arrayNumber } = this.state;
    if (has(slides, [selectedSlide, 'video'])) {
        const scale = getScale.call(this);
        const rect = get(
            slides,
            [selectedSlide, 'video', arrayNumber, 'rect'],
            getDefaultRect.call(this)
        );
        this.setState({ crop: restoreRect.call(this, rect, scale) });
    } else {
        this.setState({
            crop: {},
        });
    }
};

export const downloadVideo = async function (vaultId) {
    const { downloadVideo } = this.props;

    try {
        let interval;

        this.setState(
            { showUploadModal: true, worker: { progress: 0, total: 100 } },
            () => {
                interval = setInterval(() => {
                    const { progress } = this.props;
                    const percent = Math.round(
                        (progress.loaded / progress.total) * 100
                    );
                    this.setState({ worker: { progress: percent } });
                });
            }
        );

        const video = await downloadVideo(vaultId);

        this.setState({ video, worker: { progress: 100, total: 100 } }, () => {
            clearInterval(interval);
            setTimeout(() => this.setState({ showUploadModal: false }), 50);
        });
    } catch (err) {
        if (isFunction(this.props.toggleError)) {
            this.props.toggleError(handleError(err));
        }
    }
};

export const setVaultVideo = async function (vaultId, title) {
    const { username, uploadVideo } = this.props;
    // NOTE: might need a mini version of "populateFormWithVaultData() and populatePermissionsAndStatus()"

    try {
        console.log('adding vault video to ACE');

        const exists = await this.checkIfVideoExists(vaultId);

        if (!exists) {
            const response = await uploadVideo({
                operation: 'create',
                video: vaultId,
                vaultId,
                title,
                username,
            });
            console.log('response: ', response);
        }
    } catch (err) {
        if (isFunction(this.props.toggleError)) {
            this.props.toggleError(handleError(err));
        }
    }
};

export const checkIfVideoExists = async function (vaultId) {
    try {
        // this should probably be an action, but it is a pain in the ass to pass this down through props
        // NOTE: needs to check the table not the FTP folder
        const { data } = await axios.get(`/api/ace/video/${vaultId}`);
        return data;
    } catch (err) {
        return Promise.resolve(false);
    }
};

export const changeSlide = function ({ currentTarget: { value } }) {
    const selectedSlide = parseInt(value) - 1;
    const arrayNumber = 0;

    this.setState({ selectedSlide, arrayNumber }, () => {
        const { slides } = this.state;
        if (has(slides, [selectedSlide, 'video'])) {
            const scale = getScale.call(this);
            // if for some reason there's video data but no rect
            if (!has(slides, [selectedSlide, 'video', arrayNumber, 'rect'])) {
                this.setState({
                    slides: set(
                        slides,
                        [selectedSlide, 'video', arrayNumber, 'rect'],
                        getDefaultRect.call(this)
                    ),
                });
            }
            // RCL, bug fix for undefined 'rect'
            const rect = get(
                slides,
                [selectedSlide, 'video', arrayNumber, 'rect'],
                getDefaultRect.call(this)
            );
            // end-fix
            this.setState({ crop: restoreRect.call(this, rect, scale) });
        } else {
            this.setState({ crop: {} });
        }
    });
};

export const changeVideoNumber = function ({ currentTarget: { value } }) {
    const { selectedSlide, slides } = this.state;

    this.setState({ arrayNumber: parseInt(value) - 1 }, () => {
        const { arrayNumber } = this.state;
        const scale = getScale.call(this);
        const defaultVideo = {
            path: '',
            rect: getDefaultRect.call(this),
        };

        // if there's no video, create one
        if (!has(slides, [selectedSlide, 'video'])) {
            slides[selectedSlide].video = defaultVideo;
            this.setState({
                crop: restoreRect.call(this, getDefaultRect.call(this, scale)),
            });
        }

        // if it doesn't have a video at that index
        // make a new video object
        if (!has(slides, [selectedSlide, 'video', arrayNumber])) {
            slides[selectedSlide].video[arrayNumber] = defaultVideo;
            this.setState({
                slides,
                crop: restoreRect.call(this, getDefaultRect.call(this), scale),
            });
        } else {
            // set crop from that array entry
            const rect = get(slides, [
                selectedSlide,
                'video',
                arrayNumber,
                'rect',
            ]);
            this.setState({ crop: restoreRect.call(this, rect, scale) });
        }
    });
};

export const onCropChange = function (crop, percentCrop) {
    const { selectedSlide, slides } = this.state;
    this.setState({ crop: percentCrop }, () => {
        const rect = getRect.call(this, crop);
        if (!has(slides, [selectedSlide, 'video'])) {
            set(slides, [selectedSlide, 'video', 0, 'rect'], rect);
        } else {
            const { arrayNumber } = this.state;
            set(slides, [selectedSlide, 'video', arrayNumber, 'rect'], rect);
        }

        this.setState({ slides });
    });
};

export const onPathChange = function (value) {
    if (value) {
        const { arrayNumber, slides, selectedSlide } = this.state;
        const val = get(value, 'document_number__v', value);

        if (!has(slides, [selectedSlide, 'video'])) {
            set(slides, [selectedSlide, 'video', arrayNumber], {
                path: val,
                rect: getDefaultRect.call(this),
            });
        } else {
            set(slides, [selectedSlide, 'video', arrayNumber, 'path'], val);
        }

        this.setState({ slides });
    }
};

export const computeVideoRect = (
    rect = { x: 0, y: 0, height: 0, width: 0 },
    slideWidth = 1730,
    slideHeight = 1080
) => ({
    left: `${(parseInt(rect.x) * 100) / slideWidth}%`,
    top: `${(parseInt(rect.y) * 100) / slideHeight}%`,
    width: `${(parseInt(rect.width) * 100) / slideWidth}%`,
    height: `${(parseInt(rect.height) * 100) / slideHeight}%`,
});

export const getVideoPath = (path) => `/api/video/${path}`;

export const onFileChange = function ({ currentTarget: { files } }) {
    const file = files[0];

    if (file) {
        this.setState({ file }, async () => {
            // check to see if the file exists on the server
            // show confirm modal
            const fileExists = await this.checkIfVideoExists(
                path.basename(file.name, path.extname(file.name))
            );

            console.log('file exists?', fileExists);

            if (fileExists) {
                this.setState({ showOverwriteModal: true });
            } else {
                this.uploadVideo(file);
            }
        });
    }
};

export const onLoadedVideoData = function (event) {
    const {
        srcElement: { naturalWidth, naturalHeight },
    } = event.nativeEvent;
    const { slides, selectedSlide, arrayNumber } = this.state;
    const scale = getScale.call(this);

    // Function to calculate default rect based on video's aspect ratio
    const getAspectRatioRect = () => {
        // Calculate the aspect ratio
        const aspectRatio = naturalWidth / naturalHeight;
        this.setState({ aspect: aspectRatio });

        // Define native dimensions based on aspect ratio
        // Scaling down the dimensions to take up 1/3rd of the area
        const scalingFactor = Math.sqrt(3); // Square root of 3 for 1/3rd area
        const width = naturalWidth / scalingFactor;
        const height = naturalHeight / scalingFactor;

        // Calculate the position to center the crop
        const x = (naturalWidth - width) / 2;
        const y = (naturalHeight - height) / 2;
        return { x, y, width, height };
    };

    if (!has(slides, [selectedSlide, 'video', arrayNumber, 'rect'])) {
        console.log('[NO RECT DATA]');
        const defaultRect = getAspectRatioRect();
        set(slides, [selectedSlide, 'video', arrayNumber, 'rect'], defaultRect);

        this.setState({
            slides,
            crop: restoreRect.call(this, defaultRect),
        });
    } else {
        console.log('[HAS RECT DATA]');
        const rect = get(slides, [selectedSlide, 'video', arrayNumber, 'rect']);
        this.setState({ crop: restoreRect.call(this, rect, scale) });
    }
};

export const uploadVideo = async function (file) {
    const { username, vaultId, fetchWorker, postForm } = this.props;
    const { arrayNumber, slides, selectedSlide } = this.state;

    const uploadFile = !file ? this.state.file : file;

    const name = replace(path.basename(uploadFile.name, '.mp4'), '/', '-');
    const title = `${vaultId}-embedded-${slides[selectedSlide].id}-${arrayNumber}`;

    const formData = new FormData();

    formData.append('video', uploadFile);
    formData.append('title', title);
    formData.append('vaultId', name);
    formData.append('username', username);
    formData.append('embedded', 'true');
    formData.append('operation', 'create');

    const {
        data: { id },
    } = await postForm(formData);

    const { data: worker } = await fetchWorker(id);

    this.setState(
        {
            showUploadModal: true,
            worker,
        },
        () =>
            (this.interval = setInterval(
                this.workerInterval.bind(this, id),
                4000
            ))
    );
};

export const workerInterval = async function (workerId) {
    const { fetchAceVideos, fetchWorker } = this.props;
    const { arrayNumber, slides, selectedSlide } = this.state;

    try {
        const { data: worker } = await fetchWorker(workerId);

        this.setState({ worker }, async () => {
            if (Math.round(worker.progress) === 100) {
                clearInterval(this.interval);
                await fetchAceVideos();

                if (has(slides, [selectedSlide, 'video'])) {
                    set(
                        slides,
                        [selectedSlide, 'video', arrayNumber, 'path'],
                        worker.job.data.formData.vaultId
                    );
                } else {
                    set(
                        slides,
                        [selectedSlide, 'video'],
                        [
                            {
                                rect: getDefaultRect.call(this),
                                path: worker.job.data.formData.vaultId,
                            },
                        ]
                    );
                }

                this.setState({ slides, showUploadModal: false });
            }
        });
    } catch (err) {
        const { toggleError } = this.props;
        toggleError(handleError(err));
    }
};

export const toggleVideoModal = function (edit = false) {
    const { slides, selectedSlide, arrayNumber, showChooseVideoModal } =
        this.state;

    console.log('editing video: ', edit);

    // if it's on the first arrayNumber don't bump
    // otherwise, increment the array number

    // we also want to clone the old values so that we can restore them
    const oldVals = get(slides, [selectedSlide, 'video']);

    if (!edit && !showChooseVideoModal) {
        const newArrayNumber =
            arrayNumber === 0 &&
            !has(slides, [selectedSlide, 'video', arrayNumber, 'path'])
                ? arrayNumber
                : get(slides, [selectedSlide, 'video'], []).length;

        this.setState(
            {
                arrayNumber: newArrayNumber,
                oldVals: clone(oldVals),
            },
            () => this.setState({ showChooseVideoModal: !showChooseVideoModal })
        );
    } else if (edit && !showChooseVideoModal) {
        // if we're editing, we don't want to increment the array counter
        this.setState(
            {
                oldVals: clone(oldVals),
            },
            () => this.setState({ showChooseVideoModal: !showChooseVideoModal })
        );
    } else {
        this.setState({ showChooseVideoModal: !showChooseVideoModal });
    }
};

export const addVideo = function () {
    const {
        slides,
        selectedSlide,
        arrayNumber: previousArrayNumber,
    } = this.state;
    const oldVals = cloneDeep(slides);

    if (!slides[selectedSlide].video) {
        slides[selectedSlide].video = [];
    }

    // set the array number to the last index
    const arrayNumber = slides[selectedSlide].video.length;
    slides[selectedSlide].links.push({
        url: '',
        rect: getDefaultRect.call(this),
    });

    this.setState({
        oldVals,
        slides,
        arrayNumber,
        previousArrayNumber,
        crop: restoreRect.call(this, getDefaultRect.call(this)),
    });
};

export const initialize = function (self) {
    self.setCrop = setCrop.bind(self);
    self.setScale = setScale.bind(self);
    self.changeVideoNumber = changeVideoNumber.bind(self);
    self.changeSlide = changeSlide.bind(self);
    self.getDeckJson = getDeckJson.bind(self);
    self.preloadImages = preloadImages.bind(self);
    self.clear = clear.bind(self, 'video');
    self.clearAll = clearAll.bind(self, 'video');
    self.downloadVideo = downloadVideo.bind(self);
    self.onPathChange = onPathChange.bind(self);
    self.onCropChange = onCropChange.bind(self);
    self.onImageLoaded = onImageLoaded.bind(self);
    self.onLoadedVideoData = onLoadedVideoData.bind(self);
    self.submitVideo = submit.bind(self, 'video');
    self.nextVideo = nextObject.bind(self, 'video');
    self.prevVideo = prevObject.bind(self, 'video');
    self.onFileChange = onFileChange.bind(self);
    self.uploadVideo = uploadVideo.bind(self);
    self.workerInterval = workerInterval.bind(self);
    self.manuallyUpdateJson = manuallyUpdateJson.bind(self);
    self.setVaultVideo = setVaultVideo.bind(self);
    self.restoreOldVals = restoreOldVals.bind(self);
    self.toggleVideoModal = toggleVideoModal.bind(self);
    self.downloadJson = downloadJson.bind(self);
    self.setSlideJson = setSlideJson.bind(self);
    self.setBackupSlide = setBackupSlide.bind(self);
    self.checkIfVideoExists = checkIfVideoExists.bind(self);
    self.nextSlide = nextSlide.bind(self);
    self.prevSlide = prevSlide.bind(self);
    self.addVideo = addVideo.bind(self);
};
