import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import bsCustomFileInput from 'bs-custom-file-input';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';

class FileInput extends React.Component {
    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.state = {
            inputKey: null,
            fileName: '',
        };
    }

    onChange = event => {
        this.props.onChange(event);

        this.setState({
            fileName: event.currentTarget.files[0].name,
            inputKey: Math.random().toString(36),
        });
    };

    clear = () => {
        this.props.clear();

        const el = document.getElementById(`${this.props.id}`);
        el.innerText = 'Browse';

        this.setState({ fileName: '' });
    };

    handleClick = e => {
        this.input.current.click();
    };

    componentDidMount() {
        bsCustomFileInput.init();
    }

    componentWillUnmount() {
        bsCustomFileInput.destroy();
    }

    render() {
        const { disabled, id, name, accept, clear } = this.props;
        const { inputKey } = this.state;

        return (
            <InputGroup>
                {isFunction(clear) && (
                    <InputGroup.Prepend>
                        <Button
                            variant='danger'
                            onClick={this.clear}
                            disabled={disabled}>
                            X
                        </Button>
                    </InputGroup.Prepend>
                )}

                <Form.Control
                    placeholder='Browse...'
                    defaultValue={this.state.fileName}
                />

                <input
                    type='file'
                    id={id}
                    name={name}
                    ref={this.input}
                    style={{ display: 'none' }}
                    accept={this.props.accept}
                    onChange={this.onChange}
                />

                <InputGroup.Append>
                    <Button
                        variant='outline-secondary'
                        onClick={this.handleClick}
                        disabled={disabled}>
                        Browse
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        );
    }
}

FileInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    clear: PropTypes.func,
};

export default FileInput;
