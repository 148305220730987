import types from '../types';

const initialState = {
    animated: false,
    documentId: '',
    crop: {},
    countryCodes: [],
    mainCategory: '',
    secondCategory: '',
    internalMainCategory: '',
    internalSecondCategory: '',
    internalThirdCategory: '',
    externalMainCategory: '',
    externalSecondCategory: '',
    externalThirdCategory: '',
    keywords: '',
    title: '',
    vaultId: '',
    language: 'English (US)',
    languageCode: 'en',
    contentZone: 'US',
    fileName: '',
    image: null,
    imageScale: null,
    zip: null,
    pdf: null,
    status: 'live',
    permissions: 'pub',
    qpa: 'false',
    username: '',
    audio: null,
    video: null,
    operation: 'create',
    notify: false,
    contentType: '',
    exposure: '',
    grouped: false,
    useExperimentalImageMatcher: false,
    useAutomaticVideoAndLinkPlacement: false,
    customThumbnail: false,
    relatedItems: '',
    wistiaUrl: null,
    test: false,
    transition: '',
    departments: '',
    conditions: '',
    resizeRects: false,
};

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_FORM:
            if (typeof action.payload.name === 'string') {
                return {
                    ...state,
                    [action.payload.name]: action.payload.value,
                };
            } else if (typeof action.payload.name === 'object') {
                return {
                    ...state,
                    ...action.payload.name,
                };
            }
        default:
            return state;
    }
};

export default formReducer;
