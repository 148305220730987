import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';

import { css } from 'aphrodite';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Typeahead } from 'react-bootstrap-typeahead';
import Editor from 'react-simple-code-editor';
import clone from 'lodash/clone';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import Carousel from './Carousel';
import HelpModal from '../help/videoEditorHelp';
import ResultModal from '../shared/ResultModal';
import UploaderModal from '../shared/UploaderModal';
import OverwriteModal from './OverwriteModal';
import VideoModal from './VideoModal';
import EditorButtonGroup from '../editor/EditorButtonGroup';
import EditorSlideSelector from '../editor/EditorSlideSelector';
import JsonSlideSelector from './JsonSlideSelector';

import { initialize, state } from './helpers/videos';
import { getVaultId } from './helpers';

import styles from './styles';

class DeckVideoEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...clone(state),
            isShiftPressed: false,
            editableJson: JSON.stringify(state.slides ?? [], null, 2),
        };
        this.image = null;
        this.interval = null;
        this.intitialize = initialize.bind(this);
        this.intitialize(this);
    }

    componentDidMount() {
        if (typeof window.onresize === 'function') {
            window.onresize = null;
        }

        window.onresize = this.setScale;
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount() {
        window.onresize = null;
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.state.slides !== prevState.slides &&
            typeof this.state.slides !== 'string'
        ) {
            this.setState({
                editableJson: JSON.stringify(this.state.slides, null, 3),
            });
        }
    }

    handleKeyDown = event => {
        if (event.key === 'Shift') {
            this.setState(prevState => ({
                isShiftPressed: true,
                crop: { ...prevState.crop, aspect: prevState.aspect },
            }));
        } else if (event.key === 'Delete') {
            if (this.state.property) {
                this.clear(this.state.property);
            }
        }
    };

    handleKeyUp = event => {
        if (event.key === 'Shift') {
            this.setState(prevState => ({
                isShiftPressed: false,
                crop: { ...prevState.crop, aspect: null },
            }));
        }
    };

    changeDeck = ([deck]) => {
        if (!deck) return;

        const vaultId = getVaultId(deck);

        console.log('deck: ', deck);

        if (deck) {
            this.setState(
                {
                    deck, // needs to use array value or typeahead crashes
                    isDam: vaultId?.includes('DAM'),
                },
                async () => await this.getDeckJson({ vaultId })
            );
        }
    };

    setAspectRatio = aspect => this.setState({ aspect });

    render() {
        const { aceVideos, vaultVideos, decks, username, progress } =
            this.props;

        const {
            crop,
            done,
            deck,
            deckIsLoaded,
            deckIsInvalid,
            backup,
            hasBackups,
            backupJson,
            error,
            file,
            images,
            isDam,
            loading,
            selectedSlide,
            slides,
            showHelpModal,
            showJson,
            showUploadModal,
            showChooseVideoModal,
            showOverwriteModal,
            showResultModal,
            selectedBackupSlide,
            arrayNumber,
            upload,
            worker,
            property,
        } = this.state;

        const slide = slides[selectedSlide] || {};
        const hasVideo = !!slide.video;

        const hasRect =
            get(slide, ['video', arrayNumber, 'path']) &&
            get(slide, ['video', arrayNumber, 'rect']);

        return (
            <>
                <Container>
                    <Form>
                        <Form.Row>
                            <h1>Video Editor</h1>
                            <p
                                onClick={() =>
                                    this.setState({
                                        showHelpModal:
                                            !this.state.showHelpModal,
                                    })
                                }>
                                ⓘ
                            </p>
                        </Form.Row>
                        <Form.Row>
                            <Carousel
                                arrayNumber={arrayNumber}
                                crop={crop}
                                deck={deck.vaultId}
                                deckIsLoaded={deckIsLoaded}
                                deckIsInvalid={deckIsInvalid}
                                hasObject={hasVideo}
                                images={images}
                                slides={slides}
                                selectedSlide={selectedSlide}
                                showChooseVideoModal={showChooseVideoModal}
                                changeSelectedObject={this.changeVideoNumber}
                                onCropChange={this.onCropChange}
                                onImageLoaded={this.onImageLoaded}
                                onLoadedVideoData={this.onLoadedVideoData}
                                setAspectRatio={this.setAspectRatio}
                                nextSlide={this.nextSlide}
                                prevSlide={this.prevSlide}
                                property='video'
                                isLoading={this.state.isLoading}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Vault ID</Form.Label>
                                <Typeahead
                                    id='deck-typeahead'
                                    options={decks}
                                    labelKey={option => getVaultId(option)}
                                    placeholder='Enter deck vault ID'
                                    onChange={this.changeDeck}
                                    isInvalid={deckIsInvalid}
                                    isValid={deckIsLoaded && !deckIsInvalid}
                                />

                                <Form.Control.Feedback type='invalid'>
                                    Deck is invalid
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type='valid'>
                                    Deck is valid
                                </Form.Control.Feedback>
                            </Form.Group>
                            <EditorSlideSelector
                                deckIsLoaded={deckIsLoaded}
                                property={property}
                                changeSlide={this.changeSlide}
                                slides={slides}
                                selectedSlide={selectedSlide}
                            />

                            {hasBackups && (
                                <JsonSlideSelector
                                    deckIsLoaded={deckIsLoaded}
                                    property={property}
                                    setSlideJson={this.setSlideJson}
                                    setBackupSlide={this.setBackupSlide}
                                    slides={get(backupJson, [backup, 'slides'])}
                                    selectedSlide={selectedBackupSlide}
                                />
                            )}
                        </Form.Row>
                        <EditorButtonGroup
                            arrayNumber={arrayNumber}
                            deckIsLoaded={deckIsLoaded}
                            deckIsInvalid={deckIsInvalid}
                            property={property}
                            togglePropertyModal={this.toggleVideoModal}
                            clear={this.clear}
                            clearAll={this.clearAll}
                            addObject={this.addVideo}
                            hasRect={hasRect}
                        />

                        <Form.Row>
                            <Form.Group as={Col}>
                                <Button
                                    variant='primary'
                                    onClick={this.submitVideo}
                                    disabled={!deckIsLoaded || deckIsInvalid}
                                    block>
                                    {loading && (
                                        <Spinner
                                            as='span'
                                            animation='grow'
                                            role='status'
                                            aria-hidden='true'
                                            size='sm'
                                        />
                                    )}
                                    Save
                                </Button>
                                <Button
                                    variant='primary'
                                    onClick={this.downloadJson}
                                    disabled={!deckIsLoaded}
                                    block>
                                    Download JSON
                                </Button>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className={css(styles.json)}>
                            <Col>
                                <Editor
                                    value={this.state.editableJson}
                                    onValueChange={this.manuallyUpdateJson}
                                    highlight={code => {
                                        highlight(
                                            code || '{}',
                                            languages.json,
                                            'language-json'
                                        );
                                    }}
                                    padding={10}
                                    style={{
                                        fontFamily:
                                            '"Fira code", "Fira Mono", monospace',
                                        fontSize: 18,
                                        backgroundColor: '#282c34',
                                        color: '#212529',
                                        borderRadius: '4px',
                                        boxShadow:
                                            '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                />
                            </Col>
                        </Form.Row>
                    </Form>
                </Container>
                <HelpModal
                    show={showHelpModal}
                    handleClose={() =>
                        this.setState({
                            showHelpModal: !this.state.showHelpModal,
                        })
                    }
                />

                <UploaderModal show={showUploadModal} worker={worker} />
                <OverwriteModal
                    show={showOverwriteModal}
                    file={file}
                    handleClose={() =>
                        this.setState({
                            showOverwriteModal: !this.state.showOverwriteModal,
                        })
                    }
                    uploadVideo={this.uploadVideo}
                />

                <VideoModal
                    file={file}
                    show={showChooseVideoModal}
                    arrayNumber={arrayNumber}
                    deckIsLoaded={deckIsLoaded}
                    slides={slides}
                    selectedSlide={selectedSlide}
                    videos={upload === 1 ? vaultVideos : aceVideos}
                    upload={upload}
                    username={username}
                    progress={progress}
                    oldVals={this.state.oldVals}
                    clearVideo={this.clear}
                    downloadVideo={this.downloadVideo}
                    uploadVideo={this.props.uploadVideo}
                    setVaultVideo={this.setVaultVideo}
                    setUpload={value => this.setState({ upload: value })}
                    onPathChange={this.onPathChange}
                    onFileChange={this.onFileChange}
                    handleClose={() =>
                        this.setState({
                            showChooseVideoModal:
                                !this.state.showChooseVideoModal,
                        })
                    }
                    restoreOldVals={this.restoreOldVals}
                />

                <ResultModal
                    show={showResultModal}
                    vaultId={deck.vaultId}
                    error={error}
                    successMsg={`${deck.vaultId} was successfully updated`}
                    errMsg={
                        error || `There was an error updating ${deck.vaultId}`
                    }
                    handleClose={() =>
                        this.setState({
                            showResultModal: !this.state.showResultModal,
                        })
                    }
                />
            </>
        );
    }
}

DeckVideoEditor.propTypes = {
    aceVideos: PropTypes.array.isRequired,
    vaultVideos: PropTypes.array.isRequired,
    decks: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    uploadVideo: PropTypes.func.isRequired,
    downloadVideo: PropTypes.func.isRequired,
    clearVideo: PropTypes.func.isRequired,
    setVaultVideo: PropTypes.func.isRequired,
    setUpload: PropTypes.func.isRequired,
    onPathChange: PropTypes.func.isRequired,
    onFileChange: PropTypes.func.isRequired,
};

DeckVideoEditor.defaultProps = {
    aceVideos: [],
    vaultVideos: [],
    decks: [],
};

export default DeckVideoEditor;
