import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import has from 'lodash/has';

const JsonSlideSelector = ({
    backup,
    property,
    deckIsLoaded,
    slides,
    selectedSlide,
    setSlideJson,
    setBackupSlide,
}) => (
    <>
        <Form.Group as={Col} xs={2}>
            <Form.Label>Backup Slide</Form.Label>
            <Form.Control
                as='select'
                onChange={setBackupSlide}
                disabled={!deckIsLoaded || slides.length === 0}
                value={selectedSlide.toString()}
                custom>
                {slides ? (
                    slides.map((slide, idx) => (
                        <option key={idx} value={idx}>
                            {idx + 1}{' '}
                            {has(slide, [property]) ? `(${property})` : ''}
                        </option>
                    ))
                ) : (
                    <option>N/A</option>
                )}
            </Form.Control>
        </Form.Group>
        <Form.Group as={Col} xs={2}>
            <Form.Label>Source</Form.Label>
            <Form.Control
                as='select'
                onChange={setSlideJson}
                disabled={!deckIsLoaded || slides.length === 0}
                value={backup}
                custom>
                <option value='sqlIndexJson'>SQL</option>
                <option value='expIndexJson'>Experimental Image Matcher</option>
            </Form.Control>
        </Form.Group>
    </>
);

JsonSlideSelector.propTypes = {
    backup: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    deckIsLoaded: PropTypes.bool.isRequired,
    slides: PropTypes.array,
    selectedSlide: PropTypes.number.isRequired,
    setSlideJson: PropTypes.func.isRequired,
    setBackupSlide: PropTypes.func.isRequired,
};

export default JsonSlideSelector;
