import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

const LinkModal = ({
    arrayNumber,
    show,
    deckIsLoaded,
    hasUrls,
    slides,
    selectedSlide,
    handleClose,
    restoreOldVals,
    onChangeUrl,
}) => {
    const handleCancel = () => {
        restoreOldVals();
        handleClose();
    };

    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{hasUrls ? 'Edit Link' : 'Add Link'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Target</Form.Label>
                            <Form.Control
                                name='url'
                                type='text'
                                placeholder='Url, Slide Number or Vault number, e.g, IMP-1234'
                                value={
                                    slides?.[selectedSlide]?.links?.[
                                        arrayNumber
                                    ]?.url || ''
                                }
                                onChange={onChangeUrl}
                                disabled={!deckIsLoaded}
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleCancel}>
                    Cancel
                </Button>
                <Button variant='primary' onClick={handleClose}>
                    Select
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

LinkModal.propTypes = {
    arrayNumber: PropTypes.number.isRequired,
    show: PropTypes.bool.isRequired,
    deckIsLoaded: PropTypes.bool.isRequired,
    hasUrls: PropTypes.bool.isRequired,
    slides: PropTypes.arrayOf(
        PropTypes.shape({
            video: PropTypes.arrayOf(
                PropTypes.shape({
                    path: PropTypes.string,
                    rect: PropTypes.shape({
                        x: PropTypes.number,
                        y: PropTypes.number,
                        width: PropTypes.number,
                        height: PropTypes.number,
                    }),
                })
            ),
            links: PropTypes.arrayOf(
                PropTypes.shape({
                    url: PropTypes.string,
                    rect: PropTypes.shape({
                        x: PropTypes.number,
                        y: PropTypes.number,
                        width: PropTypes.number,
                        height: PropTypes.number,
                    }),
                })
            ),
            animations: PropTypes.arrayOf(
                PropTypes.shape({
                    frameId: PropTypes.string,
                })
            ),
        })
    ).isRequired,
    onChangeUrl: PropTypes.func.isRequired,
    selectedSlide: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired,
    restoreOldVals: PropTypes.func.isRequired,
};

LinkModal.defaultProps = {
    show: false,
    deckIsLoaded: false,
    hasUrls: false,
};

export default LinkModal;
