import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { v4 as uuidv4 } from 'uuid';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import StandaloneAlerts from './Alerts';
import CountrySelector from './CountrySelector';
import ContentZoneSelector from './ContentZoneSelector';
import DeckTypeahead from './DeckTypeahead';
import FileInput from './FileInput';
import LanguageSelector from './LanguageSelector';
import TransitionSelector from './TransitionSelector';

import HelpModal from '../help/deckUploaderHelp';

import {
    handleError,
    handleResponse,
    onChange,
    populateFormWithVaultData,
    populatePermissionsAndStatus,
    printCategory,
} from './helpers';

class DeckUploader extends React.Component {
    state = {
        isIbr: false,
        isDam: false,
        loading: false,
        loadingPptx: false,
        done: false,
        warn: false,
        error: false,
        download: null,
        notify: false,
        showHelp: false,
        mode: 'deploy',
        deckExists: false,
        warnAspectRatio: false,
    };

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
        this.setState({ error });
    }

    onVaultIdChange = ([value]) => {
        if (!value) return;

        const { downloadSource, updateForm } = this.props;

        this.setState({ download: null, done: false });

        console.log('deck: ', value);

        updateForm('vaultId', value.document_number__v);
        updateForm('jobId', uuidv4());
        updateForm('departments', value.department__c);
        updateForm('conditions', value.condition__c);

        this.setState(
            {
                isDam: value.document_number__v.toLowerCase().includes('dam'),
                isIbr: value.document_number__v.toLowerCase().includes('ibr'),
            },
            async () => {
                try {
                    // populate fields
                    const { id } = await this.populateFormWithVaultData(
                        value.document_number__v
                    );

                    await this.populatePermissionsAndStatus(
                        'PRS',
                        value.document_number__v
                    );

                    // download document attachment
                    this.setState({ loadingPptx: true });

                    const { data: source } = await downloadSource(id);

                    this.setState({
                        loadingPptx: false,
                        download: source,
                    });
                } catch (err) {
                    console.error(err);
                    this.setState({ error: handleError(err) });
                }
            }
        );
    };

    onChange = onChange.bind(this);

    populateFormWithVaultData = populateFormWithVaultData.bind(this);

    populatePermissionsAndStatus = populatePermissionsAndStatus.bind(this);

    printCategory = printCategory.bind(this);

    toggleHelpModal = () => {
        const { showHelp } = this.state;
        this.setState({ showHelp: !showHelp });
    };

    toggleAspectRatioWarningModal = () => {
        const { warnAspectRatio } = this.state;
        this.setState({ warnAspectRatio: !warnAspectRatio });
    };

    submit = async () => {
        const { form, fetchContent, postForm, updateDeckData } = this.props;
        const { isDam } = this.state;

        const hasZip = !isNull(form.zip);

        const formData = new FormData();
        formData.append('documentId', form.documentId);
        formData.append('animated', form.animated);
        formData.append('mainCategory', form.mainCategory);
        formData.append('secondCategory', form.secondCategory);
        formData.append('keywords', form.keywords);
        formData.append('title', form.title);
        formData.append('languageCode', form.languageCode);
        formData.append('contentZone', form.contentZone);
        formData.append('countryCodes', form.countryCodes.toString());
        formData.append('permissions', form.permissions);
        formData.append('qpa', form.qpa);
        formData.append('status', form.status);
        formData.append('vaultId', form.vaultId);
        formData.append('username', this.props.username);
        formData.append('operation', form.operation);
        formData.append(
            'contentType',
            form.vaultId.includes('DAM') ? 'DAM' : 'PRS'
        );
        formData.append('exposure', form.exposure);
        formData.append('grouped', form.grouped);
        formData.append('relatedItems', form.relatedItems);
        formData.append('descriptor', form.aceProDescriptor);
        formData.append('jobId', form.jobId);
        formData.append(
            'useExperimentalImageMatcher',
            form.useExperimentalImageMatcher
        );
        formData.append(
            'useAutomaticVideoAndLinkPlacement',
            form.useAutomaticVideoAndLinkPlacement
        );
        formData.append('transition', form.transition);
        formData.append(
            'departments',
            form.departments ? form.departments.join(',') : ''
        );
        formData.append(
            'conditions',
            form.conditions ? form.conditions.join(',') : ''
        );
        formData.append('resizeRects', form.resizeRects);

        if (hasZip) {
            formData.append('zip', form.zip);
        }

        if (isDam) {
            formData.append('notify', form.notify.toString());
        }

        try {
            this.setState({ loading: true });

            if (hasZip) {
                const response = await postForm(formData);
                await handleResponse.call(this, formData, response);
            } else {
                // post to route that only updates data
                await updateDeckData(formData);

                this.setState(
                    {
                        loading: false,
                        done: true,
                    },
                    async () => await fetchContent('PRS')
                );
            }
        } catch (err) {
            console.error(err);

            this.setState({
                loading: false,
                error: handleError(err),
            });
        }
    };

    render() {
        const {
            decks,
            fileType,
            locals,
            countries,
            form: {
                animated,
                useExperimentalImageMatcher,
                useAutomaticVideoAndLinkPlacement,
                permissions,
                countryCodes,
                contentZone,
                title,
                mainCategory,
                secondCategory,
                internalMainCategory,
                internalSecondCategory,
                internalThirdCategory,
                externalMainCategory,
                externalSecondCategory,
                externalThirdCategory,
                language,
                keywords,
                vaultId,
                notify,
                operation,
                zip,
                grouped,
                transition,
            },
            updateForm,
        } = this.props;

        const {
            mode,
            deckExists,
            done,
            error,
            warn,
            download,
            loading,
            loadingPptx,
            isDam,
            isIbr,
            showHelp,
        } = this.state;

        return (
            <>
                <Container>
                    <StandaloneAlerts
                        done={done}
                        error={error}
                        warn={warn}
                        file={fileType}
                        mode={mode}
                        setError={error => this.setState({ error })}
                        setDone={done => this.setState({ done })}
                        setWarn={warn => this.setState({ warn })}
                    />

                    <Form method='post' encType='multipart/form-data'>
                        <Form.Row>
                            <h1>Upload Deck</h1>
                            <p
                                onClick={this.toggleHelpModal}
                                style={{ ':hover': 'pointer' }}>
                                ⓘ
                            </p>
                        </Form.Row>
                        <Form.Row>
                            <DeckTypeahead
                                accept='.pptx'
                                decks={decks}
                                onChange={this.onVaultIdChange}
                                labelKey='document_number__v'
                                loading={loadingPptx || isEmpty(decks)}
                                deckExists={deckExists}
                                selected={vaultId}
                                download={download}
                                mode={mode}
                                setMode={mode => this.setState({ mode })}
                            />
                        </Form.Row>
                        {mode === 'deploy' && (
                            <Form.Row>
                                <Form.Group as={Col} controlId='zip'>
                                    <Form.Label>Zip file</Form.Label>
                                    <FileInput
                                        name='zip'
                                        id='zip'
                                        accept='.zip'
                                        clear={() => updateForm('zip', null)}
                                        onChange={this.onChange}
                                        download={download}
                                        disabled={!vaultId}
                                    />
                                </Form.Group>
                            </Form.Row>
                        )}

                        <Form.Row>
                            <Form.Group as={Col} controlId='title'>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    placeholder='Enter title'
                                    name='title'
                                    onChange={this.onChange}
                                    value={title}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId='keywords'>
                                <Form.Label>Keywords</Form.Label>
                                <Form.Control
                                    placeholder='Enter comma seperated keywords'
                                    name='keywords'
                                    onChange={this.onChange}
                                    value={keywords}
                                />
                            </Form.Group>
                        </Form.Row>
                        {!isDam ? (
                            <>
                                <Form.Row>
                                    <Form.Group
                                        as={Col}
                                        controlId='internalCategories'>
                                        <Form.Label>
                                            Internal Categories
                                        </Form.Label>
                                        <Form.Control
                                            placeholder={
                                                loading && !zip
                                                    ? 'Loading...'
                                                    : ''
                                            }
                                            name='categories'
                                            onChange={this.onChange}
                                            value={this.printCategory(
                                                internalMainCategory,
                                                internalSecondCategory,
                                                internalThirdCategory
                                            )}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group
                                        as={Col}
                                        controlId='externalCategories'>
                                        <Form.Label>
                                            External Categories
                                        </Form.Label>
                                        <Form.Control
                                            placeholder={
                                                loading && !zip
                                                    ? 'Loading...'
                                                    : ''
                                            }
                                            name='categories'
                                            onChange={this.onChange}
                                            value={this.printCategory(
                                                externalMainCategory,
                                                externalSecondCategory,
                                                externalThirdCategory
                                            )}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </>
                        ) : (
                            <Form.Row>
                                <Form.Group
                                    as={Col}
                                    controlId='internalCategories'>
                                    <Form.Label>Dam Categories</Form.Label>
                                    <Form.Control
                                        placeholder={
                                            loading && !zip ? 'Loading...' : ''
                                        }
                                        name='categories'
                                        onChange={this.onChange}
                                        value={this.printCategory(
                                            mainCategory,
                                            secondCategory
                                        )}
                                        readOnly
                                    />
                                </Form.Group>
                            </Form.Row>
                        )}

                        <Form.Row></Form.Row>
                        <Form.Row>
                            <LanguageSelector
                                onChange={this.onChange}
                                selected={language}
                                locals={locals}
                            />

                            <ContentZoneSelector
                                onChange={this.onChange}
                                selected={contentZone}
                            />
                        </Form.Row>
                        <Form.Row>
                            <CountrySelector
                                countries={countries}
                                onChange={this.onChange}
                                selected={countryCodes}
                            />
                        </Form.Row>
                        <TransitionSelector
                            onChange={this.onChange}
                            selected={transition}
                        />

                        {isDam && (
                            <Form.Group controlId='grouped'>
                                <Form.Check
                                    custom
                                    name='grouped'
                                    type='checkbox'
                                    label='Grouped?'
                                    checked={grouped}
                                    onChange={({
                                        currentTarget: { checked },
                                    }) => updateForm('grouped', checked)}
                                />
                            </Form.Group>
                        )}

                        <Form.Group controlId='animated'>
                            <Form.Check
                                custom
                                name='animated'
                                type='checkbox'
                                label='Animated?'
                                onChange={this.onChange}
                                checked={animated}
                                disabled={mode === 'update'}
                            />
                        </Form.Group>
                        {!isIbr && (
                            <Form.Group controlId='permissions'>
                                <Form.Check
                                    custom
                                    name='permissions'
                                    type='checkbox'
                                    label='Restricted?'
                                    checked={permissions === 'R'}
                                    onChange={({
                                        currentTarget: { checked },
                                    }) =>
                                        updateForm(
                                            'permissions',
                                            checked ? 'R' : 'pub'
                                        )
                                    }
                                />
                            </Form.Group>
                        )}

                        {operation === 'update' && (
                            <Form.Group controlId='useExperimentalImageMatcher'>
                                <Form.Check
                                    custom
                                    disabled={useAutomaticVideoAndLinkPlacement}
                                    name='useExperimentalImageMatcher'
                                    type='checkbox'
                                    label='Use experimental image matcher to copy videos and links?'
                                    onChange={this.onChange}
                                    checked={useExperimentalImageMatcher}
                                />
                            </Form.Group>
                        )}

                        <Form.Group controlId='useAutomaticVideoAndLinkPlacement'>
                            <Form.Check
                                custom
                                disabled={
                                    useExperimentalImageMatcher || animated
                                }
                                name='useAutomaticVideoAndLinkPlacement'
                                type='checkbox'
                                label='Use experimental pptx parser to copy videos and links?'
                                onChange={this.onChange}
                                checked={useAutomaticVideoAndLinkPlacement}
                            />
                        </Form.Group>
                        {isDam && operation === 'update' && (
                            <Form.Group controlId='notify'>
                                <Form.Check
                                    custom
                                    name='notify'
                                    type='checkbox'
                                    label='Send notification email?'
                                    onChange={this.onChange}
                                    value={notify}
                                    disabled={!isDam}
                                />
                            </Form.Group>
                        )}

                        <Button
                            onClick={this.submit}
                            disabled={!vaultId || (mode === 'deploy' && !zip)}
                            size='lg'
                            block>
                            {loading && (
                                <Spinner
                                    as='span'
                                    animation='grow'
                                    role='status'
                                    aria-hidden='true'
                                    size='sm'
                                />
                            )}

                            {mode === 'deploy' ? 'Upload' : 'Update'}
                        </Button>
                    </Form>
                </Container>
                <HelpModal show={showHelp} handleClose={this.toggleHelpModal} />
            </>
        );
    }
}

DeckUploader.propTypes = {
    decks: PropTypes.array.isRequired,
    fileType: PropTypes.string.isRequired,
    locals: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired,
    fetchContent: PropTypes.func.isRequired,
    postForm: PropTypes.func.isRequired,
    updateDeckData: PropTypes.func.isRequired,
    downloadSource: PropTypes.func.isRequired,
    updateForm: PropTypes.func.isRequired,
};

export default DeckUploader;
