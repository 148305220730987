import { css } from 'aphrodite';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';

import styles from './styles';

const DeckUploaderHelp = ({ show, handleClose }) => {
    const [url, setUrl] = useState();

    return (
        <Modal show={show} size='lg'>
            <Modal.Header>
                <Modal.Title>Steps to upload a Powerpoint deck:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <ol>
                            <li
                                className={css(styles.listItem)}
                                onMouseOver={() =>
                                    setUrl(
                                        require('../../../public/images/vaultIdform.png')
                                    )
                                }>
                                Type the Vault ID of the document you wish to
                                upload to ACE Pro
                            </li>
                            <li
                                className={css(styles.listItem)}
                                onMouseOver={() =>
                                    setUrl(
                                        require('../../../public/images/dropdown.png')
                                    )
                                }>
                                Select the document from autocomplete dropdown
                                and click the "download" button once it appears
                            </li>
                            <li
                                className={css(styles.listItem)}
                                onMouseOver={() =>
                                    setUrl(
                                        require('../../../public/images/3enableeditingcropped.png')
                                    )
                                }>
                                Open the <code>.pptx</code> file and click
                                "enable editing"
                            </li>
                            <li
                                className={css(styles.listItem)}
                                onMouseOver={() =>
                                    setUrl(
                                        require('../../../public/images/4exporttopngcropped.png')
                                    )
                                }>
                                Export Powerpoint to <code>.pngs</code> by going
                                to{' '}
                                <code>
                                    File > Export > Change File Type > PNG
                                    Portable Network Graphics (*.png)
                                </code>
                                .
                            </li>
                            <li>Select "All Slides" and export wherever</li>
                            <li
                                className={css(styles.listItem)}
                                onMouseOver={() =>
                                    setUrl(
                                        require('../../../public/images/5compressfoldercropped.png')
                                    )
                                }>
                                Right click and click{' '}
                                <code>
                                    Send to > Compressed (zipped) folder
                                </code>
                            </li>
                            <li
                                className={css(styles.listItem)}
                                onMouseOver={() =>
                                    setUrl(
                                        require('../../../public/images/7browseforfile.png')
                                    )
                                }>
                                Open then file browser in the deck uploader form
                                and navigate to the <code>.zip</code> file you
                                just made
                            </li>
                            <li>
                                Double check fields (Vault information may be
                                incomplete)
                            </li>
                            <li
                                className={css(styles.listItem)}
                                onMouseOver={() =>
                                    setUrl(
                                        require('../../../public/images/8upload.png')
                                    )
                                }>
                                Click <code>upload</code> button
                            </li>
                            <li>
                                Go to the tasks tab to view the upload progress
                            </li>
                        </ol>
                    </Col>
                    <Col>
                        <img
                            className='img-fluid'
                            src={
                                url ||
                                require('../../../public/images/missing_1730_1.png')
                            }
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' block onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeckUploaderHelp;
