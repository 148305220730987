import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import clone from 'lodash/clone';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import Carousel from '../../editor/Carousel';
import HelpModal from '../../help/videoEditorHelp';
import ResultModal from '../../shared/ResultModal';
import UploaderModal from '../../shared/UploaderModal';
import VideoModal from '../../editor/VideoModal';
import OverwriteModal from '../../editor/OverwriteModal';
import EditorButtonGroup from '../../editor/EditorButtonGroup';
import EditorSlideSelector from '../../editor/EditorSlideSelector';

import { initialize, state } from '../../editor/helpers/videos';

class VaultDeckVideoEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...clone(state),
            deck: this.props.vaultId,
            slides: this.props.slides || [],
            backup: this.props.useExperimentalImageMatcher
                ? 'expIndexJson'
                : 'sqlIndexJson',
            isShiftPressed: false,
        };
        this.image = null;
        this.interval = null;
        this.initialize = initialize.bind(this);
        this.initialize(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { open, slides } = this.props;

        if (slides !== prevProps.slides) {
            this.setState({ slides });
        } else if (open !== prevProps.open && !open) {
            window.onresize = null;
        } else if (open !== prevProps.open && open) {
            window.onresize = this.setScale;
            setTimeout(this.setScale, 100);
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.setScale);
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setScale);
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    handleKeyDown = event => {
        if (event.key === 'Shift') {
            this.setState(prevState => ({
                isShiftPressed: true,
                crop: { ...prevState.crop, aspect: prevState.aspect },
            }));
        }
    };

    handleKeyUp = event => {
        if (event.key === 'Shift') {
            this.setState(prevState => ({
                isShiftPressed: false,
                crop: { ...prevState.crop, aspect: null },
            }));
        }
    };

    setAspectRatio = aspect => this.setState({ aspect });

    toggleHelpModal = () =>
        this.setState({ showHelpModal: !this.state.showHelpModal });

    toggleUploadModal = () =>
        this.setState({
            showChooseVideoModal: !this.state.showChooseVideoModal,
        });

    toggleResultModal = () =>
        this.setState({ showResultModal: !this.state.showResultModal });

    toggleOverwriteModal = () =>
        this.setState({ showOverwriteModal: !this.state.showOverwriteModal });

    render() {
        const {
            aceVideos,
            vaultVideos,
            username,
            images,
            progress,
            deckIsLoaded,
            deckIsInvalid,
        } = this.props;

        const {
            crop,
            deck,
            error,
            file,
            showHelpModal,
            showUploadModal,
            showChooseVideoModal,
            showOverwriteModal,
            selectedSlide,
            slides,
            arrayNumber,
            upload,
            worker,
        } = this.state;

        const slide = slides[selectedSlide] || {};
        const hasVideo = !!slide.video;

        const hasRect =
            get(slide, ['video', arrayNumber, 'path']) &&
            get(slide, ['video', arrayNumber, 'rect']);

        return (
            <>
                {error && (
                    <Alert
                        variant='danger'
                        dismissible
                        onClose={() => this.setState({ error: false })}>
                        {error || 'There was an error, check the console'}
                    </Alert>
                )}

                <Form className='px-2 py-2'>
                    <Form.Row className='d-flex'>
                        <Carousel
                            setAspectRatio={this.setAspectRatio}
                            arrayNumber={arrayNumber}
                            crop={crop}
                            deck={deck}
                            deckIsLoaded={deckIsLoaded}
                            deckIsInvalid={deckIsInvalid}
                            hasObject={hasVideo}
                            images={images}
                            slide={slide}
                            slides={slides}
                            selectedSlide={selectedSlide}
                            showChooseVideoModal={showChooseVideoModal}
                            changeSelectedObject={this.changeVideoNumber}
                            onCropChange={this.onCropChange}
                            onImageLoaded={this.onImageLoaded}
                            onLoadedVideoData={this.onLoadedVideoData}
                            nextSlide={this.nextSlide}
                            prevSlide={this.prevSlide}
                            property={this.state.property}
                            isLoading={this.state.isLoading}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Video path</Form.Label>
                            <Form.Control
                                type='text'
                                readOnly
                                placeholder={get(slide, [
                                    'video',
                                    arrayNumber,
                                    'path',
                                ])}
                            />
                        </Form.Group>
                        <EditorSlideSelector
                            deckIsLoaded={deckIsLoaded}
                            property={this.state.property}
                            changeSlide={this.changeSlide}
                            slides={slides}
                            selectedSlide={selectedSlide}
                        />
                    </Form.Row>
                    <EditorButtonGroup
                        addObject={this.addVideo}
                        property={this.state.property}
                        togglePropertyModal={this.toggleVideoModal}
                        clear={this.clear}
                        deckIsLoaded={deckIsLoaded}
                        deckIsInvalid={deckIsInvalid}
                        hasRect={hasRect}
                        arrayNumber={arrayNumber}
                        clearAll={this.clearAll}
                    />
                </Form>
                <hr />
                <HelpModal
                    show={showHelpModal}
                    handleClose={this.toggleHelpModal}
                />

                <UploaderModal show={showUploadModal} worker={worker} />

                <OverwriteModal
                    show={showOverwriteModal}
                    file={file}
                    handleClose={this.toggleOverwriteModal}
                    uploadVideo={this.uploadVideo}
                />

                <VideoModal
                    file={file}
                    show={showChooseVideoModal}
                    arrayNumber={arrayNumber}
                    hasVideo={hasVideo}
                    deckIsLoaded={deckIsLoaded}
                    slides={slides}
                    selectedSlide={selectedSlide}
                    videos={upload === 1 ? vaultVideos : aceVideos}
                    upload={upload}
                    username={username}
                    progress={progress}
                    oldVals={this.state.oldVals}
                    clearVideo={this.clear}
                    downloadVideo={this.downloadVideo}
                    uploadVideo={this.props.uploadVideo}
                    setVaultVideo={this.setVaultVideo}
                    setUpload={value => this.setState({ upload: value })}
                    onPathChange={this.onPathChange}
                    onFileChange={this.onFileChange}
                    handleClose={this.toggleUploadModal}
                    restoreOldVals={this.restoreOldVals}
                />

                <ResultModal
                    show={false}
                    vaultId={deck}
                    error={error}
                    successMsg={`${deck} was successfully updated`}
                    errMsg={`There was an error updating ${deck}`}
                    handleClose={this.toggleResultModal}
                />
            </>
        );
    }
}

VaultDeckVideoEditor.propTypes = {
    aceVideos: PropTypes.array.isRequired,
    vaultVideos: PropTypes.array.isRequired,
    username: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    progress: PropTypes.number.isRequired,
    deckIsLoaded: PropTypes.bool.isRequired,
    deckIsInvalid: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    slides: PropTypes.array,
    vaultId: PropTypes.string.isRequired,
    useExperimentalImageMatcher: PropTypes.bool.isRequired,
    uploadVideo: PropTypes.func.isRequired,
};

VaultDeckVideoEditor.defaultProps = {
    slides: [],
};

export default VaultDeckVideoEditor;
