import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron';

const Home = () => {
  const history = useHistory();

  return (
    <Container>
			<Row className='pt-5'>
				<Jumbotron>
					<h1>Welcome to the ACE CMS.</h1>
					<p>
						To add a deck click the "uploader" link above or "Add deck" below.
						Instructions to upload a deck can be accessed by clicking the "ⓘ"
						button next to the form title.
					</p>
					<Button variant='primary' onClick={() => history.push('/uploader')}>
						Add deck
					</Button>
				</Jumbotron>
			</Row>
		</Container>);

};

export default Home;