import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { FaBan, FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import isNumber from 'lodash/isNumber';

const EditorButtonGroup = ({
    property,
    addObject,
    arrayNumber,
    togglePropertyModal,
    clear,
    clearAll,
    deckIsLoaded,
    deckIsInvalid,
    hasRect,
}) => (
    <Form.Row>
        <Form.Group as={Col}>
            <ButtonGroup style={{ width: '100%' }}>
                <ButtonGroup className='w-75'>
                    <Button
                        variant='primary'
                        onClick={() => {
                            togglePropertyModal();
                            addObject();
                        }}
                        disabled={!deckIsLoaded || deckIsInvalid}>
                        Add
                        <FaPlus
                            style={{
                                verticalAlign: 'middle',
                                marginLeft: '12px',
                                marginBottom: '4px',
                            }}
                        />{' '}
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => togglePropertyModal()}
                        disabled={!hasRect || !isNumber(arrayNumber)}>
                        Edit
                        <FaEdit
                            style={{
                                verticalAlign: 'middle',
                                marginLeft: '12px',
                                marginBottom: '4px',
                            }}
                        />{' '}
                    </Button>
                    <Button
                        onClick={clear}
                        disabled={!hasRect}
                        variant='warning'>
                        Remove
                        <FaTrash
                            style={{
                                verticalAlign: 'middle',
                                marginLeft: '12px',
                                marginBottom: '4px',
                            }}
                        />{' '}
                    </Button>
                </ButtonGroup>
                <Button
                    className='w-25'
                    variant='danger'
                    onClick={clearAll}
                    disabled={!hasRect}>
                    Clear All
                    <FaBan
                        style={{
                            verticalAlign: 'middle',
                            marginLeft: '12px',
                            marginBottom: '4px',
                        }}
                    />{' '}
                </Button>
            </ButtonGroup>
        </Form.Group>
    </Form.Row>
);

EditorButtonGroup.propTypes = {
    addObject: PropTypes.func.isRequired,
    arrayNumber: PropTypes.number.isRequired,
    togglePropertyModal: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired,
    deckIsLoaded: PropTypes.bool.isRequired,
    deckIsInvalid: PropTypes.bool.isRequired,
    hasRect: PropTypes.bool.isRequired,
    property: PropTypes.string.isRequired,
};

export default EditorButtonGroup;
