import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';

const VaultLinkThumbnailConfirmModal = ({
    authorized,
    loading,
    vaultId,
    handleClose,
    show,
}) => (
    <Modal show={show} onHide={handleClose} backdrop='static' centered>
        {loading && (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Update "{vaultId}" Thumbnail?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h3>
                                {authorized
                                    ? 'Loading...'
                                    : 'Authenticating...'}
                            </h3>
                        </Col>
                        <Col>
                            <Spinner
                                as='span'
                                animation='border'
                                role='status'
                                aria-hidden='true'
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </>
        )}

        {!loading && !authorized && (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Update "{vaultId}" Thumbnail?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You are not authorized to perform this function
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                        OK
                    </Button>
                </Modal.Footer>
            </>
        )}

        {!loading && authorized && (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>Update "{vaultId}" Thumbnail?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Authenticated.</Modal.Body>
            </>
        )}
    </Modal>
);

VaultLinkThumbnailConfirmModal.propTypes = {
    authorized: PropTypes.bool,
    loading: PropTypes.bool,
    vaultId: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};

VaultLinkThumbnailConfirmModal.defaultProps = {
    authorized: false,
    loading: false,
    vaultId: '',
};

export default VaultLinkThumbnailConfirmModal;
