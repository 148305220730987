import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

const RemoverConfirmModal = ({ show, vaultId, handleClose, handleRemove }) => (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>Remove "{vaultId}"?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to remove {vaultId}?</Modal.Body>
        <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
                Cancel
            </Button>
            <Button variant='primary' onClick={handleRemove}>
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>
);

RemoverConfirmModal.propTypes = {
    show: PropTypes.bool.isRequired,
    vaultId: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
};

export default RemoverConfirmModal;
