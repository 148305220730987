import PropTypes from 'prop-types';
import { css } from 'aphrodite';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ReactCrop from 'react-image-crop';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';

import styles from './styles';
import truncate from 'lodash/truncate';
import JsonInfoHeader from './JsonInfoHeader';
import CarouselRects from './CarouselRects';
import CarouselLoader from './CarouselLoader';

const CMSCarousel = ({
    arrayNumber,
    crop,
    deck,
    hasObject,
    images,
    property,
    slides,
    selectedSlide,
    changeSelectedObject,
    onCropChange,
    onDragEnd,
    onImageLoaded,
    onLoadedVideoData,
    nextSlide,
    prevSlide,
    setAspectRatio,
    deckIsLoaded,
    isLoading,
}) => {
    const copyToClipboard = async () => {
        const { unit, ...rest } = crop;
        const transformedCrop = {};

        // Truncate values to two decimal places
        for (const [key, value] of Object.entries(rest)) {
            transformedCrop[key] = Math.round(value * 100) / 100;
        }

        await navigator.clipboard.writeText(JSON.stringify(transformedCrop));
    };

    const getImageDimensions = () => {
        const image = document.querySelector('.ReactCrop__image');

        if (image) {
            return {
                width: image.naturalWidth,
                height: image.naturalHeight,
            };
        } else {
            console.warn('No image found');
            return null;
        }
    };

    const getImageWidth = () => {
        return getImageDimensions().width;
    };

    const handleSelect = (e, idx) => {
        if (property === 'video') {
            changeSelectedObject({
                currentTarget: {
                    value: (idx + 1).toString(),
                },
            });
            // get aspect ratio of video
            setAspectRatio(e.target.videoWidth / e.target.videoHeight);
        }
    };

    const handleSelectLink = (e, idx) => {
        if (property === 'links') {
            changeSelectedObject({
                currentTarget: {
                    value: (idx + 1).toString(),
                },
            });
        }
    };

    const displayUrl = url => {
        const regex = /^\d+$/;
        if (regex.test(url)) {
            return `Slide: ${url}`;
        } else {
            return truncate(url, { length: 16 });
        }
    };

    const hasVideo =
        has(slides, [selectedSlide, 'video']) &&
        Array.isArray(slides[selectedSlide].video);
    const hasLinks =
        has(slides, [selectedSlide, 'links']) &&
        Array.isArray(slides[selectedSlide].links);

    return (
        <>
            <Button
                className={css(styles.left)}
                onClick={prevSlide}
                variant='secondary'>
                <FaChevronLeft />
            </Button>
            <Card
                style={{ width: '94%', position: 'relative' }}
                tabIndex={0}
                id='carouselId'>
                <Card.Header
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <h3>{deck || 'Preview'}</h3>
                    {hasObject && (
                        <JsonInfoHeader
                            crop={
                                slides?.[selectedSlide]?.links?.[arrayNumber]
                                    ?.rect
                            }
                            dimensions={getImageDimensions()}
                            copyToClipboard={copyToClipboard}
                        />
                    )}
                </Card.Header>

                <ReactCrop
                    crop={crop}
                    disabled={!hasObject}
                    src={
                        images[selectedSlide] ||
                        require('../../../public/images/missing_1730_1.png')
                    }
                    onChange={onCropChange}
                    onImageLoaded={onImageLoaded}
                    onDragEnd={isFunction(onDragEnd) ? onDragEnd : noop}
                    imageStyle={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        // position: 'relative',
                    }}>
                    <CarouselLoader isLoading={isLoading} />

                    <CarouselRects
                        hasVideo={hasVideo}
                        slides={slides}
                        selectedSlide={selectedSlide}
                        getImageWidth={getImageWidth}
                        handleSelect={handleSelect}
                        onLoadedVideoData={onLoadedVideoData}
                        hasLinks={hasLinks}
                        property={property}
                        displayUrl={displayUrl}
                        handleSelectLink={handleSelectLink}
                    />
                </ReactCrop>
            </Card>
            <Button
                className={css(styles.right)}
                onClick={nextSlide}
                variant='secondary'>
                <FaChevronRight />
            </Button>
        </>
    );
};

CMSCarousel.propTypes = {
    arrayNumber: PropTypes.number,
    crop: PropTypes.object,
    deck: PropTypes.string,
    hasObject: PropTypes.bool,
    images: PropTypes.array,
    property: PropTypes.string,
    slides: PropTypes.array,
    selectedSlide: PropTypes.number,
    changeSelectedObject: PropTypes.func,
    onCropChange: PropTypes.func,
    onDragEnd: PropTypes.func,
    onImageLoaded: PropTypes.func,
    onLoadedVideoData: PropTypes.func,
    nextSlide: PropTypes.func,
    prevSlide: PropTypes.func,
    setAspectRatio: PropTypes.func,
    deckIsLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

CMSCarousel.defaultProps = {
    slides: [],
    isLoading: false,
};

export default CMSCarousel;
