import React from 'react';
import { LazyLog } from 'react-lazylog';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import isEmpty from 'lodash/isEmpty';
import downloadFile from 'js-file-download';

const Logs = ({ logs }) => {
    console.log('logs: ', logs);

    const downloadLogs = () => downloadFile(logs, 'combined.log');

    return (
        <>
            {!isEmpty(logs) && (
                <Container>
                    <LazyLog
                        enableSearch
                        caseInsensitive
                        text={logs}
                        height={1000}
                        follow
                    />
                    <Row className='pt-2 justify-content-end'>
                        <Button variant='primary' onClick={downloadLogs}>
                            Download
                        </Button>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default Logs;
