import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import find from 'lodash/find';
import PropTypes from 'prop-types';

import FileInput from './FileInput';
import ResultModal from '../shared/ResultModal';

import { base64ArrayBuffer } from '../uploader/helpers';

import {
    clearLinkAttachment,
    downloadAceLinkThumbnailPreview,
    downloadLinkThumbnail,
    fetchVaultAttachmentInfo,
    fetchVaultLinks,
    publishLinkThumbnail,
    uploadLinkThumbnail,
    uploadVaultLinkThumbnail,
} from '../../redux/actions';

const PreviewModal = ({ previewImage, show, handleClose, handleConfirm }) => (
    <Modal show={show} onHide={handleClose} backdrop='static' size='lg'>
        <Modal.Header closeButton>
            <Modal.Title>Upload to Vault?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Image
                src={base64ArrayBuffer(previewImage)}
                className='mx-auto d-block vertical-center'
            />
        </Modal.Body>
        <Modal.Footer>
            <Button block onClick={handleConfirm}>
                OK
            </Button>
        </Modal.Footer>
    </Modal>
);

PreviewModal.propTypes = {
    previewImage: PropTypes.instanceOf(ArrayBuffer),
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
};

const LinkThumbnailUpload = () => {
    const dispatch = useDispatchRequest();
    const [attachment, setAttachment] = useState(null);
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(false);
    const [url, setUrl] = useState('');
    const [attachmentId, setAttachmentId] = useState();
    const { data: links } = useQuery({
        type: 'FETCH_VAULT_LINKS',
        action: fetchVaultLinks,
        multiple: true,
        autoLoad: true,
    });
    const { load: loadAttachment } = useQuery({
        type: 'FETCH_VAULT_LINK_THUMBNAIL',
        action: fetchVaultAttachmentInfo,
        variables: [selected],
    });
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);
    const [success, setSuccess] = useState(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [file, setFile] = useState(null);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [modalPreview, setModalPreview] = useState(null);

    const onSelectChange = option => {
        const [selected] = option;

        if (selected) {
            setUrl(selected.url__c);
            setSelected(selected.id);
            setAttachment(null);
            setPreview(null);
        }
    };

    useEffect(() => {
        const setThumbnails = async () => {
            if (selected) {
                setLoading(true);
                const { data: attachmentInfo } = await loadAttachment();
                const attachmentId = attachmentInfo?.id;

                if (attachmentId) {
                    setAttachmentId(attachmentId);
                    const { data } = await dispatch(
                        downloadLinkThumbnail(selected, attachmentId)
                    );
                    setAttachment(data);

                    const formData = new FormData();
                    formData.append(
                        'file',
                        new File([data], attachmentInfo.filename__v, {
                            type: attachmentInfo.format__v,
                        })
                    );
                    await dispatch(uploadLinkThumbnail(selected, formData));
                    const { data: thumbnail } = await dispatch(
                        downloadAceLinkThumbnailPreview(selected)
                    );
                    setPreview(thumbnail);
                } else {
                    setAttachment(null);
                }
                setLoading(false);
            }
        };
        setThumbnails();
    }, [selected, url]);

    const onChange = async ({ currentTarget: { files } }) => {
        setFile(files[0]);
        setModalPreview(await files[0].arrayBuffer());
        setShowPreviewModal(true);
    };

    const handleConfirmPreview = useCallback(async () => {
        setAttachment(await file?.arrayBuffer());
        await uploadAttachment();
        setShowPreviewModal(false);
    }, [file]);

    const uploadAttachment = useCallback(async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            await dispatch(uploadVaultLinkThumbnail(selected, formData));
            await dispatch(uploadLinkThumbnail(selected, formData));
            const { data: previewData } = await dispatch(
                downloadAceLinkThumbnailPreview(selected)
            );
            setPreview(previewData);
            setLoading(false);
            clearModal();
            setSuccess('Attachment uploaded to Vault.');
            setShowResultModal(true);
        } catch (err) {
            setError(err);
            setShowResultModal(true);
        }
    }, [attachment, file]);

    const clearAttachment = async () => {
        try {
            await dispatch(clearLinkAttachment(selected, attachmentId));
            setAttachment(null);
            setStatus('Attachment cleared.');
            setShowResultModal(true);
        } catch (err) {
            setError('Error clearing attachment.');
            setShowResultModal(true);
        }
    };

    const publish = async () => {
        try {
            await dispatch(publishLinkThumbnail(selected));
            setSuccess(
                `Thumbnail for ${
                    find(links, { id: selected }).name__v
                } was successfully published to ACE.`
            );
            setShowResultModal(true);
        } catch (err) {
            setError(err);
            setShowResultModal(true);
        }
    };

    const clearModal = () => {
        setError(null);
        setStatus(null);
        setSuccess(null);
    };

    return (
        <Container>
            <h1>Upload Link Thumbnail</h1>
            <Form>
                <Form.Group controlId='links'>
                    <Form.Label>Select Link</Form.Label>
                    <Typeahead
                        id='linkTypeahead'
                        onChange={onSelectChange}
                        value={selected}
                        options={links}
                        labelKey='name__v'
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Link Url</Form.Label>
                    <Form.Control defaultValue={url} readOnly></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Upload Custom Thumbnail</Form.Label>
                    <FileInput
                        accept='image/png'
                        onChange={onChange}
                        disabled={attachment}
                    />
                </Form.Group>
                <Form.Group>
                    <Button
                        onClick={clearAttachment}
                        block
                        disabled={!attachment}>
                        Clear Attachment
                    </Button>
                </Form.Group>
                <Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} md='6'>
                            <Card>
                                <Card.Header>Vault Thumbnail</Card.Header>
                                {!loading && selected && attachment ? (
                                    <Card.Img
                                        variant='top'
                                        src={base64ArrayBuffer(attachment)}
                                    />
                                ) : loading ? (
                                    <div className='text-center vertical-center'>
                                        <Spinner
                                            animation='border'
                                            role='status'>
                                            <span className='sr-only'>
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <Card.Text>No attachment found.</Card.Text>
                                )}
                            </Card>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Card>
                                <Card.Header>Ace Thumbnail Preview</Card.Header>
                                {!loading && preview ? (
                                    <Card.Img
                                        variant='top'
                                        style={{
                                            width: '400px',
                                            height: '250px',
                                            margin: '25px',
                                        }}
                                        className='mx-auto d-block vertical-center'
                                        src={base64ArrayBuffer(preview)}
                                    />
                                ) : loading ? (
                                    <div className='text-center vertical-center'>
                                        <Spinner
                                            animation='border'
                                            role='status'>
                                            <span className='sr-only'>
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <Card.Text>No attachment found.</Card.Text>
                                )}
                            </Card>
                        </Form.Group>
                    </Form.Row>
                    <Form.Group>
                        <Button onClick={publish} block>
                            Publish to ACE
                        </Button>
                    </Form.Group>
                </Form.Group>
            </Form>
            <PreviewModal
                previewImage={modalPreview}
                show={showPreviewModal}
                handleClose={() => setShowPreviewModal(false)}
                handleConfirm={() => handleConfirmPreview()}
            />

            <ResultModal
                show={showResultModal}
                vaultId={selected}
                error={error}
                preview={false}
                statusMsg={status}
                successMsg={success}
                errMsg={error}
                handleClose={() => setShowResultModal(false)}
            />
        </Container>
    );
};

LinkThumbnailUpload.propTypes = {
    links: PropTypes.array,
};

export default LinkThumbnailUpload;
