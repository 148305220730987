import { BarLoader } from 'react-spinners';
import React from 'react';
import PropTypes from 'prop-types';

const CarouselLoader = ({ isLoading }) => {
    console.log('isLoading', isLoading);

    return (
        <>
            {isLoading && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        zIndex: 10,
                    }}
                />
            )}
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 50,
                }}>
                <BarLoader color='skyblue' loading={isLoading} width={300} />
            </div>
        </>
    );
};

CarouselLoader.propTypes = {
    isLoading: PropTypes.bool,
};

CarouselLoader.defaultProps = {
    isLoading: false,
};

export default CarouselLoader;
