import types from '../types';

const initialState = {
    loaded: 0,
    total: 0,
};

const progressReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_PROGRESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default progressReducer;
