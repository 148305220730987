import Button from 'react-bootstrap/Button';
import { FaCopy } from 'react-icons/fa';
import React from 'react';
import PropTypes from 'prop-types';

const JsonInfoHeader = ({ crop, copyToClipboard }) => {
    // Return null right away if crop is undefined
    if (!crop) {
        return null;
    }

    // Check if any of the necessary crop properties are invalid
    const isInvalidCrop = Object.entries(crop).some(
        ([key, value]) =>
            !['unit', 'aspect'].includes(key) &&
            (value === undefined || isNaN(Number(value)))
    );

    // If any value (excluding non-numeric keys) is invalid, return null to render nothing
    if (isInvalidCrop) {
        return null;
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '15px' }}>
                x: {crop.x.toFixed(2)}, y: {crop.y.toFixed(2)}, width:{' '}
                {crop.width.toFixed(2)}, height: {crop.height.toFixed(2)}
            </span>
            <Button variant='outline-primary' onClick={copyToClipboard}>
                Copy
                <FaCopy
                    style={{
                        marginLeft: '5px',
                        marginBottom: '5px',
                    }}
                />
            </Button>
        </div>
    );
};

JsonInfoHeader.propTypes = {
    crop: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
    }), // Note: The PropTypes do not mark these as .isRequired, because crop can be undefined.
    copyToClipboard: PropTypes.func.isRequired,
};

export default JsonInfoHeader;
