import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';

const UploaderModal = ({ show, worker }) => (
    <Modal show={show} onHide={noop} centered>
        <Modal.Header>
            <Modal.Title>Uploading...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {worker.state === 'failed' ? (
                <ProgressBar
                    variant='danger'
                    now={worker.progress}
                    label={`${Math.round(worker.progress)}%`}
                />
            ) : (
                <ProgressBar
                    animated={Math.round(worker.progress) !== 100}
                    now={worker.progress}
                    label={`${Math.round(worker.progress)}%`}
                />
            )}

            {worker.state === 'failed' && <p>{worker.reason}</p>}
        </Modal.Body>
    </Modal>
);

UploaderModal.propTypes = {
    show: PropTypes.bool.isRequired,
    worker: PropTypes.object.isRequired,
};

export default UploaderModal;
