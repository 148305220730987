import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
    left: {
        width: '3%',
    },
    right: {
        width: '3%',
    },
    pre: {
        backgroundColor: '#e9ecef',
        width: '100%',
    },
    json: {
        paddingTop: '10px',
    },
    video: {
        position: 'absolute',
        objectFit: 'fill',
    },
});

export default styles;
