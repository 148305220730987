import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Error from './Error';
import Success from './Success';
import Warning from './Warning';

const test =
    window.location.href.includes('test') ||
    window.location.href.includes('localhost');
const staging = window.location.href.includes('stage');
const location = staging ? 'stage' : 'site';

const getRoute = (vaultId, test, exposure) => {
    return test
        ? `https://test.abiomedcontentengine.com/ACE/site/public/play/${vaultId}${
              exposure && exposure === 'internal' ? '?internal' : ''
          }`
        : `https://abiomedcontentengine.com/ACE/${location}/public/play/${vaultId}${
              exposure && exposure === 'internal' ? '?internal' : ''
          }`;
};

const ResultModal = ({
    error,
    show,
    errMsg,
    statusMsg,
    successMsg,
    vaultId,
    preview,
    exposure,
    handleClose,
}) => (
    <Modal show={show} centered>
        <Modal.Header>
            <Modal.Title>
                {error ? 'Error' : !isEmpty(statusMsg) ? statusMsg : successMsg}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {error && isEmpty(statusMsg) ? (
                <Error error={errMsg} />
            ) : !isEmpty(statusMsg) ? (
                <Warning />
            ) : (
                <Success />
            )}
        </Modal.Body>
        <Modal.Footer className='d-flex flex-column'>
            {!error && preview && (
                <Button
                    variant='primary'
                    size='lg'
                    block
                    href={getRoute(vaultId, test, exposure)}>
                    Preview
                </Button>
            )}

            <Button
                variant='primary'
                size='lg'
                style={{ marginLeft: '0px' }}
                block
                onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
);

ResultModal.propTypes = {
    error: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    errMsg: PropTypes.string,
    statusMsg: PropTypes.string,
    successMsg: PropTypes.string,
    vaultId: PropTypes.string.isRequired,
    preview: PropTypes.bool,
    exposure: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    handleClose: PropTypes.func.isRequired,
};

ResultModal.defaultProps = {
    error: false,
    exposure: false,
    preview: true,
    statusMsg: '',
    errMsg: '',
    successMsg: '',
};

export default ResultModal;
