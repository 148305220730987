import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { JSONTree } from 'react-json-tree';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { useQuery } from '@redux-requests/react';
import noop from 'lodash/noop';
import {
    fetchCategoriesCombined,
    fetchCategoriesExternal,
    fetchCategoriesInternal,
} from '../redux/actions';

const Categories = () => {
    const darkModeSetting = useSelector(state => state.darkMode);
    const [result, setResult] = useState({});
    const [rebuildFunction, setRebuildFunction] = useState(noop);

    const { load: rebuildInternal } = useQuery({
        type: 'FETCH_CATEGORIES_INTERNAL',
        action: fetchCategoriesInternal,
    });

    const { load: rebuildExternal } = useQuery({
        type: 'FETCH_CATEGORIES_EXTERNAL',
        action: fetchCategoriesExternal,
    });

    const { load: rebuildCombined } = useQuery({
        type: 'FETCH_CATEGORIES_COMBINED',
        action: fetchCategoriesCombined,
    });

    useEffect(() => {
        const fetchResult = async () => {
            if (rebuildFunction) {
                try {
                    setResult({});
                    const data = await rebuildFunction;
                    setResult(data);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchResult();
    }, [rebuildFunction]);

    return (
        <Container>
            <Row className='pt-5'>
                <Jumbotron style={{ width: '100%' }}>
                    <h1>Rebuild Categories</h1>
                    <p>
                        Click the button to rebuild the respective categories.
                    </p>
                    <Button
                        variant='primary'
                        size='lg'
                        className='mr-3'
                        onClick={() => setRebuildFunction(rebuildInternal)}>
                        Rebuild Internal
                    </Button>
                    <Button
                        variant='primary'
                        size='lg'
                        className='mr-3'
                        onClick={() => setRebuildFunction(rebuildExternal)}>
                        Rebuild External
                    </Button>
                    <Button
                        variant='primary'
                        size='lg'
                        onClick={() => setRebuildFunction(rebuildCombined)}>
                        Rebuild All
                    </Button>
                    <Card className='mt-5'>
                        <JSONTree
                            data={result}
                            theme={{ scheme: 'solarized' }}
                            invertTheme={!darkModeSetting}
                        />
                    </Card>
                </Jumbotron>
            </Row>
        </Container>
    );
};

export default Categories;
