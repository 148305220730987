import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import last from 'lodash/last';
import has from 'lodash/has';
import get from 'lodash/get';

import {
    cleanWorkers,
    clearFailedWorkers,
    clearFinishedWorkers,
    clearWorker,
    fetchWorkers,
    restartWorker,
} from '../redux/actions';

const WorkerMonitor = () => {
    const dispatch = useDispatchRequest();
    const [optionsOpen, setOptionsOpen] = useState(false);
    const { data: workers, load: loadWorkers } = useQuery({
        type: 'FETCH_WORKERS',
        action: fetchWorkers,
        autoLoad: true,
    });
    const interval = useRef(null);

    const handleRestartWorker = useCallback(
        async worker => {
            return dispatch(restartWorker(worker));
        },
        [dispatch]
    );

    const handleClearWorker = useCallback(
        async worker => {
            await dispatch(clearWorker(worker));
        },
        [dispatch]
    );

    useEffect(() => {
        interval.current = setInterval(loadWorkers, 10000);
        return () => clearInterval(interval.current);
    }, []);

    const lastWorker = last(workers);

    return (
        <Container>
            <Row>
                <Card style={{ width: '100%', marginBottom: '5px' }}>
                    <Card.Header>
                        Worker Options{' '}
                        <span
                            className='float-right'
                            style={{ fontWeight: 'bold', cursor: 'pointer' }}
                            onClick={() => setOptionsOpen(!optionsOpen)}>
                            {optionsOpen ? '>' : 'V'}
                        </span>
                    </Card.Header>
                    <Card.Body
                        className='justify-content-between'
                        style={{ display: optionsOpen ? 'none' : 'flex' }}>
                        <Button
                            variant='danger'
                            style={{ flex: '33%' }}
                            onClick={() => dispatch(cleanWorkers())}>
                            Clear All
                        </Button>
                        <Button
                            variant='danger'
                            style={{
                                flex: '33%',
                                marginRight: '5px',
                                marginLeft: '5px',
                            }}
                            onClick={() => dispatch(clearFinishedWorkers())}>
                            Clear All Finished
                        </Button>
                        <Button
                            variant='danger'
                            style={{ flex: '33%' }}
                            onClick={() => dispatch(clearFailedWorkers())}>
                            Clear All Failed
                        </Button>
                    </Card.Body>
                </Card>
            </Row>
            {lastWorker && lastWorker.failedReason && (
                <Row style={{ marginBottom: '10px' }}>
                    <Card bg='danger' style={{ width: '100%', color: 'white' }}>
                        <Card.Header>Error: Worker {lastWorker.id}</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                {lastWorker.failedReason &&
                                    `${lastWorker.failedReason}`}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            )}

            {workers &&
                workers
                    .sort((a, b) => a.timestamp - b.timestamp)
                    .reverse()
                    .map((worker, idx) => (
                        <Row key={worker.id}>
                            <Card
                                style={{ width: '100%', marginBottom: '5px' }}>
                                <Card.Body>
                                    <p>
                                        Worker {worker.id}:{' '}
                                        {get(
                                            worker,
                                            'data.formData.vaultId',
                                            `can't fetch Vault ID`
                                        )}{' '}
                                        (
                                        {get(
                                            worker,
                                            'data.formData.username',
                                            `can't fetch username`
                                        )}
                                        )
                                        {has(
                                            worker,
                                            'data.formData.operation'
                                        ) &&
                                            `, Operation: ${worker.data.formData.operation}`}
                                    </p>
                                    {has(worker, 'failedReason') ? (
                                        <ProgressBar
                                            variant='danger'
                                            now={worker.progress}
                                            label={`${Math.round(
                                                worker.progress
                                            )}%`}
                                        />
                                    ) : (
                                        <ProgressBar
                                            animated={!worker.finishedOn}
                                            now={worker.progress}
                                            label={`${Math.round(
                                                worker.progress
                                            )}%`}
                                        />
                                    )}
                                </Card.Body>
                                <Card.Footer>
                                    <div className='float-left'>
                                        <p
                                            style={{
                                                marginTop: '5px',
                                                marginBottom: '0px',
                                            }}>
                                            Started:{' '}
                                            {moment(worker.timestamp).format(
                                                'MMMM Do YYYY, h:mm:ss a'
                                            )}
                                            {worker.finishedOn &&
                                                `, Finished: ${moment(
                                                    worker.finishedOn
                                                ).format(
                                                    'MMMM Do YYYY, h:mm:ss a'
                                                )}`}
                                            {worker.failedReason &&
                                                `, Failed Reason: ${worker.failedReason}`}
                                        </p>
                                    </div>
                                    <ButtonToolbar className='float-right'>
                                        <Button
                                            onClick={async () =>
                                                await handleRestartWorker(
                                                    worker
                                                )
                                            }
                                            disabled={worker.progress === 100}>
                                            Restart
                                        </Button>
                                        <Button
                                            variant='secondary'
                                            onClick={() =>
                                                handleClearWorker(worker)
                                            }
                                            style={{ marginLeft: '5px' }}>
                                            Clear
                                        </Button>
                                    </ButtonToolbar>
                                </Card.Footer>
                            </Card>
                        </Row>
                    ))}
        </Container>
    );
};

export default WorkerMonitor;
