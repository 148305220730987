import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Editor from 'react-simple-code-editor';
import Hotkeys from 'react-hot-keys';
import { highlight, languages } from 'prismjs/components/prism-core';
import PropTypes from 'prop-types';

const JsonViewer = ({ isDam, damJson, slides, manuallyUpdateJson }) => {
    const [showJson, setShowJson] = useState(false);

    return (
        <Hotkeys keyName='shift+j' onKeyDown={() => setShowJson(!showJson)}>
            <Collapse in={showJson}>
                <Form>
                    <Form.Row>
                        <Col>
                            <Editor
                                value={JSON.stringify(
                                    isDam ? damJson : slides,
                                    null,
                                    3
                                )}
                                onValueChange={code => manuallyUpdateJson(code)}
                                highlight={code =>
                                    highlight(code, languages.json, 'json')
                                }
                                padding={10}
                                style={{
                                    fontFamily: 'monospace',
                                    fontSize: 12,
                                    backgroundColor: '#6c757d',
                                    width: '100%',
                                    borderRadius: '0.25rem',
                                }}
                            />
                        </Col>
                    </Form.Row>
                </Form>
            </Collapse>
        </Hotkeys>
    );
};

JsonViewer.propTypes = {
    isDam: PropTypes.bool.isRequired,
    damJson: PropTypes.object.isRequired,
    slides: PropTypes.array.isRequired,
    manuallyUpdateJson: PropTypes.func.isRequired,
};

export default JsonViewer;
