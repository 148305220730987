import React, { useCallback, useState } from 'react';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import { useUrlSearchParams } from 'use-url-search-params';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import find from 'lodash/find';
import PropTypes from 'prop-types';

import {
    authorizeUser,
    fetchContent,
    removeContent,
    removeDams,
} from '../../redux/actions';

import RemoverModal from './VaultRemoverModal';
import ResultModal from '../shared/ResultModal';

const VaultRemover = () => {
    const [{ username, sessionId, vaultId, docName, vaultDomain }] =
        useUrlSearchParams();

    const dispatch = useDispatchRequest();
    const [error, setError] = useState(false);
    const [removed, setRemoved] = useState(false);
    const [showRemoverModal, setShowRemoverModal] = useState(true);
    const [alreadyRemoved, setAlreadyRemoved] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);

    const [successMsg, setSuccessMsg] = useState(null);
    const [statusMsg, setStatusMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [notify, setNotify] = useState(false);

    const { data: content } = useQuery({
        type: 'FETCH_CONTENT',
        action: fetchContent,
        multiple: true,
        autoLoad: true,
    });
    const { data: authorized, loading: authorizing } = useQuery({
        type: 'AUTHORIZE_USER',
        action: authorizeUser,
        variables: [sessionId],
        autoLoad: true,
    });

    const handleClose = () => {
        setStatusMsg(`${vaultId} was not removed`);
        setShowRemoverModal(false);
    };

    const toggleResultModal = () => {
        setShowResultModal(!showResultModal);
    };

    const handleRemove = useCallback(async () => {
        try {
            if (authorized.ace_admin__c) {
                const entry = find(content, e => e.vaultId === vaultId);

                if (!entry) {
                    setAlreadyRemoved(true);
                    setStatusMsg(
                        `Cannot find deck. Deck has likely already been removed`
                    );
                    setShowRemoverModal(false);
                    toggleResultModal();
                } else {
                    const response = vaultId.includes('DAM')
                        ? await dispatch(removeDams(entry, username, notify))
                        : await dispatch(removeContent(entry, username));
                    console.log('removed: ', response);

                    setSuccessMsg(`${vaultId} was successfully removed`);
                    setRemoved(true);
                    setShowRemoverModal(false);
                    toggleResultModal();
                }
            } else {
                setError(
                    'Access denied. You are not authorized to perform this function'
                );
            }
        } catch (err) {
            console.error(err);
            setError(err);
            setRemoved(false);
            setShowRemoverModal(false);
        }
    }, [authorized, content, notify, vaultId]);

    return (
        <Container>
            <Breadcrumb className='pt-5'>
                <Breadcrumb.Item active={!authorized}>
                    1. Authentication
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    active={authorized && !showResultModal && showRemoverModal}>
                    2. Confirm
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    active={authorized && showResultModal && !showRemoverModal}>
                    3. Remove
                </Breadcrumb.Item>
            </Breadcrumb>
            {authorizing && !authorized && (
                <Jumbotron>
                    <h1>Authenticating...</h1>
                </Jumbotron>
            )}

            {authorized && !showResultModal && showRemoverModal && (
                <Jumbotron>
                    <h1>Confirmation</h1>
                </Jumbotron>
            )}

            {!showRemoverModal && !showResultModal && (
                <Jumbotron>
                    <h1>You may now safely close this window</h1>
                </Jumbotron>
            )}

            <RemoverModal
                isDam={vaultId.includes('DAM')}
                notify={notify}
                show={showRemoverModal}
                authorized={authorized}
                loading={authorizing}
                query={{ username, sessionId, vaultId, docName, vaultDomain }}
                handleClose={handleClose}
                handleRemove={handleRemove}
                setNotify={setNotify}
            />

            <ResultModal
                show={showResultModal}
                vaultId={vaultId}
                error={error}
                preview={false}
                statusMsg={statusMsg}
                successMsg={successMsg}
                errMsg={errorMsg}
                handleClose={toggleResultModal}
            />
        </Container>
    );
};

VaultRemover.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default VaultRemover;
