import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';

const VaultRemoverModal = ({
    authorized,
    loading,
    query,
    show,
    handleClose,
    handleRemove,
}) => (
    <Modal
        show={show}
        onHide={handleClose}
        bbackdrop='static'
        keyboard={false}
        centered>
        {loading ? (
            <>
                <Modal.Header>
                    <Modal.Title>Remove "{query.docName}"?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h3>
                                {authorized
                                    ? 'Loading...'
                                    : 'Authenticating...'}
                            </h3>
                        </Col>
                        <Col>
                            <Spinner
                                as='span'
                                animation='border'
                                role='status'
                                aria-hidden='true'
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </>
        ) : (
            <>
                <Modal.Header>
                    <Modal.Title>Remove "{query.docName}"?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose} size='lg'>
                        Cancel
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => handleRemove()}
                        disabled={!authorized}
                        size='lg'>
                        Confirm
                    </Button>
                </Modal.Footer>
            </>
        )}
    </Modal>
);

VaultRemoverModal.propTypes = {
    authorized: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    query: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
};

export default VaultRemoverModal;
