import React from 'react';
import PropTypes from 'prop-types';

function createMarkup(html) {
    return { __html: html.body.innerHTML };
}

const Error = ({ error }) => (
    <>
        <div
            className='swal2-icon swal2-error swal2-animate-error-icon'
            style={{ display: 'flex' }}>
            <span className='swal2-x-mark'>
                <span className='swal2-x-mark-line-left'></span>
                <span className='swal2-x-mark-line-right'></span>
            </span>
        </div>
        {error instanceof HTMLDocument ? (
            <div dangerouslySetInnerHTML={createMarkup(error)} />
        ) : (
            <p>{error}</p>
        )}
    </>
);

Error.propTypes = {
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(HTMLDocument),
    ]).isRequired,
};

export default Error;
