import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import PropTypes from 'prop-types';

import FileInput from '../editor/FileInputWithUpload';
import { handleError } from './helpers';

import { live, test as dev } from '../../../src/config/versions';

const VaultVideoThumbnailEditor = ({
    form,
    vaultId,
    toggleAlert,
    uploadVideoThumbnail,
}) => {
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState(new Date().getTime());

    const submit = async ({ currentTarget: { files } }) => {
        try {
            if (files && files.length > 0) {
                const file = files[0];
                const formData = new FormData();
                formData.append('status', form.status);
                formData.append('image', file);

                setLoading(true);

                await uploadVideoThumbnail(vaultId, formData);

                setLoading(false);
                setTime(new Date().getTime());
            }
        } catch (err) {
            toggleAlert('danger', handleError(err));
        }
    };

    const test =
        window.location.href.includes('test') ||
        window.location.href.includes('localhost');

    const src = test
        ? `https://test.abiomedcontentengine.com/ACE/site/public/api/v${dev}/videos/${vaultId}/thumbnail?${time}`
        : `https://abiomedcontentengine.com/ACE/site/public/api/v${live}/videos/${vaultId}/thumbnail?${time}`;

    return (
        <Form>
            <Form.Group as={Col}>
                <Card>
                    <Card.Header>Preview</Card.Header>
                    <Card.Body className='d-flex justify-content-center'>
                        {loading ? (
                            <Spinner animation='border' role='status'>
                                <span className='sr-only'>Loading...</span>
                            </Spinner>
                        ) : (
                            <img
                                alt='crop'
                                width='310px'
                                height='213.4px'
                                src={`${src}?${time}`}
                            />
                        )}
                    </Card.Body>
                </Card>
            </Form.Group>
            <Form.Group as={Col} controlId='image'>
                <Form.Label>Replace Default Thumbnail</Form.Label>
                <FileInput
                    name='thumbnail'
                    id='thumbnail'
                    accept='image/*'
                    onChange={submit}
                />
            </Form.Group>
        </Form>
    );
};

VaultVideoThumbnailEditor.propTypes = {
    form: PropTypes.object.isRequired,
    vaultId: PropTypes.string.isRequired,
    toggleAlert: PropTypes.func.isRequired,
    uploadVideoThumbnail: PropTypes.func.isRequired,
};

export default VaultVideoThumbnailEditor;
