import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import missingImage from '../../../../public/images/missing_1730_1.png';
import React from 'react';
import PropTypes from 'prop-types';

const FormPdfThumbnailViewer = ({ pdfThumbnail }) => (
    <Form.Group as={Col}>
        <Form.Row>
            <Form.Group as={Col} controlId='thumbnail'>
                <Card className='justify-content-center'>
                    <Card.Header>Preview</Card.Header>
                    <Card.Body className='d-flex justify-content-center'>
                        <img
                            alt='crop'
                            width='310px'
                            height='213.4px'
                            src={pdfThumbnail || missingImage}
                        />
                    </Card.Body>
                </Card>
            </Form.Group>
        </Form.Row>
    </Form.Group>
);

FormPdfThumbnailViewer.propTypes = {
    pdfThumbnail: PropTypes.string,
};

export default FormPdfThumbnailViewer;
